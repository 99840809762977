<template>
  <div class=" my-5 p-0">
    <div>
      <h3 class="head p-4" :class="{ 'tamil-font': selectedLanguage === 'ta', 'normal-font': selectedLanguage !== 'ta' }">{{ translations.daily }} <br>
        {{translations.marketPrice}}</h3>
    </div>
    <div class="design">
      <img src="../../assets/img/imagespro/Group 159.png" loading="lazy" alt="grop-159">
    </div>
<div class="rectangle">
<img src="../../assets/img/imagespro/Rectangle 30.png" loading="lazy" alt="reactangle-30">
</div>
    <div class="marquee bg-red">
      <router-link to="/market-price">
      <h2 class="marquee_text">
        <span class="color" v-for="item in market" :key="item.market_price_id">
          {{
            selectedLanguage === "en"
            ? item.sub_category_name
            : item.tn_sub_name
          }}
          
          <span class="material-symbols-outlined"><img src="../../assets/img/Icons/currency-rupee.svg" loading="lazy" alt=""></span>
          {{ item.price }}/{{ selectedLanguage === 'en'
                  ? item.quantity_type
                  : item.quantity_type_tamil }}
          <span
            class="material-symbols-outlined"
          ><img src="../../assets/img/Icons/geo-alt.svg" loading="lazy" alt="geo-alt"></span>
          {{
            selectedLanguage === "en"
            ? item.place
            : item.tn_place
          }} on {{ formatDate(item.created) }} ||
        </span>
      </h2>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Mulish&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Asap+Condensed&family=Montserrat&family=Mulish&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap");

.head {
  color: #fff;
  font-weight: 400;
  letter-spacing: -1px;
  font-family: "Mukta";
  position: absolute;
  z-index: 99;
  margin-top: -15px;
  left: 35px;
}
.normal-font {
  font-size: 16px; 
}

.tamil-font {
  font-size: 14px; 
}
.marquee {
  position: relative;
  font-family: "Mukta";
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;
  padding: 0.3rem 0 0.3rem 0;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.rectangle{
  position: absolute;
z-index: 9;
margin-top: -10px;
}
.design{
  position: absolute;
z-index: 99;
margin-top: -23px;
left: 10px;
}
.rectangle img{
  height: 80px;
}
.marquee_text {
  display: inline-block;
  padding-left: 100%;
  text-transform: uppercase;
  color: aliceblue;
  animation: marquee-right-left 100s linear infinite;
}

.material-symbols-outlined {
  font-size: 18px;
  margin-top: -5px;
}

.marquee_text:hover {
  animation-play-state: paused;
}

.marquee_text:hover + .marquee_text {
  animation-play-state: paused;
}

.marquee_text:not(:hover) {
  animation-play-state: running;
}

@keyframes marquee-right-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.color:nth-child(odd) {
  color: #F71E01;
  font-family: "Mukta";
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.color:nth-child(even) {
  color: #F71E01;
  font-family: "Mukta";
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}
@media (min-width: 1800px) {
  .design{
    position: absolute;
  z-index: 99;
  margin-top: -23px;
  left: 200px;
  }
  .head {
    color: #fff;
    font-weight: 400;
    letter-spacing: -1px;
    font-family: "Mukta";
    position: absolute;
    z-index: 99;
    margin-top: -15px;
    left: 235px;
  }
  }
@media (min-width: 300px) and (max-width: 768px) {

  .rectangle img{
    height: 70px;
    width: 83%;
  }
.head{
  font-size: 13px;
  margin-top: -15px;
  margin-right: 5px;
}

}

</style>
<style src="@/assets/img/style.css"></style>

<script>
import { en, ta } from "@/translations";

export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue

  data() {
    return {
      market: []
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  mounted() {
    fetch("https://vaiyal-app.herokuapp.com/get/recentlist?type=market")
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          this.market = data.data;
          // console.log(this.market);
        } else {
          console.error("Failed to fetch recent product data:", data.msg);
        }
      })
      .catch(error => {
        console.error("Error fetching recent product data:", error);
      });
  },
  methods: {
    formatDate(date) {
      const dateObject = new Date(date);
      const day = String(dateObject.getDate()).padStart(2, "0");
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const year = String(dateObject.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    }
  }
};
</script>
