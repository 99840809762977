import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../components/HomePage.vue";
import AboutPage from "../components/AboutPage.vue";
import ContactPage from "../components/ContactPage.vue";
import LoginAccount from "../components/homeaddon/LoginAccount.vue";
import AccountDelete from "../components/homeaddon/AccountDelete.vue";
import ProductDetails from "../components/ProductDetails.vue";
import PriceMarketPage from "../components/PriceMarketPage.vue";
import PricePage from "../components/PricePage.vue";
import RecommendPage from "../components/RecommendPage.vue";
import EquipDetails from "../components/EquipDetails.vue";
import BlogList from "../components/BlogList.vue";
import OffersPage from "../components/homeaddon/OffersPage.vue";
import PriceSublist from "../components/PriceSublist.vue";
import HomeBlog from "../components/HomeBlog.vue";
import FooterPage from "../components/FooterPage.vue";
import PrivacyPolicy from "../components/terms/PrivacyPolicy.vue";
import TermsConditions from "../components/terms/TermsConditions.vue";
import HomeBlogs from "../components/homeaddon/HomeMarket.vue";
import AdVayal from "../components/homeaddon/AdVayal.vue";
import PopUp from "../components/homeaddon/PopUp.vue";
import AllRecent from "../components/homeaddon/AllRecent.vue";
import AllList from "../components/AllList.vue";
import BottomAd from "../components/homeaddon/BottomAd.vue";
// import MarketPrice from '../components/marketprices/MarketPrice.vue';
import FarmPage from "../components/marketprice/FarmPage.vue";
import FarmPagefilter from "../components/marketprice/FarmPagefilter.vue";
import EquipmentChoice from "../components/marketprice/EquipmentChoice.vue";
import ProductChoice from "../components/marketprice/ProductChoice.vue";
import MarketSub from "../components/marketprices/MarketSub.vue";
import axios from "axios";
import metaDataJson from "../../src/meta.json"; // Adjust the path based on where your JSON file is located

const routes = [
    //TODO:
    {
        path: "/:location?",
        name: "Home",
        component: HomePage,
        meta: {
            title: "Home Page",
           
        },
    },
    {
        path: "/market-price",
        name: "marketPrice",
        component: PriceSublist,
        props: true,
        meta: {
            title: "pricesublist", // Placeholder title
            
        },
        beforeEnter: async (to, from, next) => {
            const categoryName = to.params.categoryName;
            const pageName = "pricesublist"; // Page name as per your requirement
            const city = to.params.city;
            const district = to.params.location;
            const created = to.params.created;
            const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            // Capitalize city and district
            const capitalizedCity = capitalizeFirstLetter(city || "");
            const capitalizedDistrict = capitalizeFirstLetter(district || "");
            try {
                // Fetch meta data from the API
                const response = await axios.get(
                    `https://vaiyal-app.herokuapp.com/meta`,
                    {
                        params: {
                            category_name: categoryName,
                            page_name: pageName,
                        },
                    }
                );

                const metaData = response.data.data;

                if (metaData) {
                    // Update the title
                    const title = metaData.title
                        .replace("{city}", capitalizedCity)
                        .replace("{district}", capitalizedDistrict);
                    to.meta.title = title;
                    document.title = to.meta.title; // Update document title

                    // Update the description
                    const description = metaData.description
                        .replace("{city}", capitalizedCity)
                        .replace("{district}", capitalizedDistrict)
                        .replace("{date}", created);
                    to.meta.metaTags[0].content = description;

                    // Update meta tags in the document head
                    const metaDescription = document.querySelector(
                        'meta[name="description"]'
                    );
                    if (metaDescription) {
                        metaDescription.setAttribute("content", description);
                    }
                    const head = metaData.title
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict)
                    to.meta.metaTags[0].content = head;
                    const metaHead = document.querySelector(
                        'meta[name="title"]'
                    );
                    if (metaHead) {
                        metaHead.setAttribute("content", head);
                    }
                }
            } catch (error) {
                console.warn("Error fetching meta data:", error);
            }

            next();
        },
    },
    {
        path: "/market-price/:categoryName-price-today-:categoryId",
        name: "MarketSub",
        component: MarketSub,
        props: true,
        meta: {
            title: "Market Page", // Placeholder title
            metaTags: [
                {
                    name: "description",
                    content:
                        "Daily markets price, farm products, farm equipment, meat, vegetables, cattle, goat, Tamil Nadu agriculture",
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            const categoryName = to.params.categoryName;

            // Dynamically update the title and meta tags
            if (categoryName) {
                to.meta.title = `Market Page ${categoryName}`;
                document.title = to.meta.title; // Update document title
                to.meta.metaTags[0].name = categoryName;

                // Update meta tag in the document head
                const metaDescription = document.querySelector(
                    'meta[name="description"]'
                );
                if (metaDescription) {
                    metaDescription.setAttribute(
                        "content",
                        `Market prices for ${categoryName}`
                    );
                }
            }

            next();
        },
    },
    {
        path: "/homeblog",
        name: "HomeBlog",
        component: HomeBlog,
    },
    {
        path: "/recomend",
        name: "RecommendPage",
        component: RecommendPage,
    },
    {
        path: "/homeblogs",
        name: "HomeBlogs",
        component: HomeBlogs,
    },
    {
        path: "/privacy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/terms",
        name: "TermsConditions",
        component: TermsConditions,
    },

    {
        path: "/advayal",
        name: "AdVayal",
        component: AdVayal,
    },
    {
        path: "/blogs",
        name: "BlogList",
        component: BlogList,
        meta: {
            title: "Vayal Blogs",
            metaTags: [
                {
                    name: "description1",
                    content:
                        "Daily markets price , farm products farm equipments meat vegtables cattels goat tamil nadu agriculture",
                },
                // Add more meta tags as needed
            ],
        },
    },
    {
        path: "/about",
        name: "About",
        component: AboutPage,
        meta: {
            title: "About Us",
            metaTags: [{ name: "About", content: "vayal about." }],
        },
    },
    {
        path: "/footer",
        name: "Footer",
        component: FooterPage,
    },
    {
        path: "/contact",
        name: "Contact",
        component: ContactPage,
        meta: {
            title: "Vayal Contact",
            metaTags: [
                {
                    name: "Contact",
                    content:
                        "Daily markets price , farm products farm equipments meat vegtables cattels goat tamil nadu agriculture",
                },
                // Add more meta tags as needed
            ],
        },
    },

    {
        path: "/pricepages", // Modify the path as per your requirement
        name: "pricemarketpage",
        component: PricePage,
        beforeEnter(to, from, next) {
            // Fetch saved data from local storage
            const savedMarket = localStorage.getItem("selectedMarket");
            const savedPlace = localStorage.getItem("selectedPlace");

            // Pass saved data to the component as props
            to.params.savedMarket = savedMarket || "";
            to.params.savedPlace = savedPlace || "0";

            next();
        },
    },
    {
        path: "/recentlist",
        name: "recentlist",
        component: PriceMarketPage,
    },

   {
        path: "/market-price/tamilnadu/:categoryName-market-price:location?/:city?",
        name: "pricesublist",
        component: PriceSublist,
        props: true,
        meta: {
            title: "pricesublist", // Placeholder title
           
        },
        beforeEnter: async (to, from, next) => {
            const categoryName = to.params.categoryName;
            const pageName = "pricesublist"; // Page name as per your requirement
            const city = to.params.city;
            const district = to.params.location;
            const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            // Capitalize city and district
            const capitalizedCity = capitalizeFirstLetter(city || "");
            const capitalizedDistrict = capitalizeFirstLetter(district || "");
            try {
                // Fetch meta data from the API
                const response = await axios.get(
                    "https://vaiyal-app.herokuapp.com/meta",
                    {
                        params: {
                            category_name: categoryName,
                            page_name: pageName,
                        },
                    }
                );

                const metaData = response.data.data;

                if (metaData) {
                    // Update the title
                    const title = metaData.title
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict);
                    to.meta.title = title;
                    document.title = to.meta.title; // Update document title

                    // Update the description
                    const description = metaData.description
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict)
                    to.meta.metaTags[0].content = description;

                    // Update meta tags in the document head
                    const metaDescription = document.querySelector(
                        'meta[name="description"]'
                    );
                    if (metaDescription) {
                        metaDescription.setAttribute("content", description);
                    }
                    const head = metaData.title
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict)
                    to.meta.metaTags[0].content = head;
                    const metaHead = document.querySelector(
                        'meta[name="title"]'
                    );
                    if (metaHead) {
                        metaHead.setAttribute("content", head);
                    }
                }
            } catch (error) {
                console.warn("Error fetching meta data:", error);
            }

            next();
        },
    },
    {
        path: "/market-price/tamilnadu/:categoryName?-market-price:location?/:city?/:created?",
        name: "pricesublist1",
        component: PriceSublist,
        props: true,
        meta: {
          title: "pricesublist1", // Placeholder title
         
      },
      beforeEnter: async (to, from, next) => {
          const categoryName = to.params.categoryName;
          const pageName = "pricesublist1"; // Page name as per your requirement
          const city = to.params.city;
          const district = to.params.location;
          const created = to.params.created;
          const capitalizeFirstLetter = (string) => {
              return string.charAt(0).toUpperCase() + string.slice(1);
          };

          // Capitalize city and district
          const capitalizedCity = capitalizeFirstLetter(city || "");
          const capitalizedDistrict = capitalizeFirstLetter(district || "");
          try {
              // Fetch meta data from the API
              const response = await axios.get(
                  `https://vaiyal-app.herokuapp.com/meta`,
                  {
                      params: {
                          category_name: categoryName,
                          page_name: pageName,
                      },
                  }
              );

              const metaData = response.data.data;

              if (metaData) {
                  // Update the title
                  const title = metaData.title
                      .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                      .replace("{district}", capitalizedDistrict);
                  to.meta.title = title;
                  document.title = to.meta.title; // Update document title

                  // Update the description
                  const description = metaData.description
                      .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                      .replace("{district}", capitalizedDistrict)
                      .replace("{date}", created);
                  to.meta.metaTags[0].content = description;

                  // Update meta tags in the document head
                  const metaDescription = document.querySelector(
                      'meta[name="description"]'
                  );
                  if (metaDescription) {
                      metaDescription.setAttribute("content", description);
                  }
                  const head = metaData.title
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict)
                    to.meta.metaTags[0].content = head;
                    const metaHead = document.querySelector(
                        'meta[name="title"]'
                    );
                    if (metaHead) {
                        metaHead.setAttribute("content", head);
                    }
              }
          } catch (error) {
              console.warn("Error fetching meta data:", error);
          }

          next();
      }
    },

    {
        path: "/market-price/tamilnadu/:categoryName-market-price:location?/:city?/:name-history-price",
        // path: "/tamilnadu/andhra-rose-history-price-erode/gobi",
        // '/market-price/tamilnadu/:categoryName-market-price:location?/:city?'
        // path: "/historyprice_of_:name?/:categoryname/:categoryType/:categoryId?/:subCategoryId?-:marketId-:marketPlaceId",
        name: "AllRecent",
        component: AllRecent,
        meta: {
            title: "VayalAgro-Recent Price",
            metaTags: [
                {
                    name: "VayalAgro-Recent",
                    content:
                        "Daily markets price, farm products, farm equipment, meat, vegetables, cattles, goat, Tamil Nadu agriculture",
                },
            ],
        },
    },
    {
        path: "/historyprice_of_:categoryname/:categoryType/:categoryId-:marketId-:marketPlaceId",
        name: "AllRecentD",
        component: AllRecent,
        meta: {
            title: "VayalAgro-Recent Price",
            metaTags: [
                {
                    name: "VayalAgro-Recent1",
                    content:
                        "Daily markets price, farm products, farm equipment, meat, vegetables, cattles, goat, Tamil Nadu agriculture",
                },
            ],
        },
    },
    {
        path: "/:location/:category/:categoryName?/:product-list/vap:id",
        name: "resultproduct",
        component: ProductDetails,
    },
    {
        path: "/:location/:category/:categoryName?/:product-list/vls:id",
        name: "livestockdetails",
        component: ProductDetails,
    },
    {
        path: "/:location/:category/:categoryName?/:product-list/vae:id",
        name: "equipment",
        component: EquipDetails,
    },
    {
        path: "/offers",
        name: "OffersPage",
        component: OffersPage,
    },
    {
        path: "/popup",
        name: "PopUp",
        component: PopUp,
    },

    {
        path: "/:location/:category/:categoryName?/:product-list/:city?", // subcat as product. need to handle DC
        name: "AllList",
        component: AllList,
        props: true,
    },

    {
        path: "/:location/:category",
        name: "FarmPage",
        component: FarmPage,
        props: true,
    },
    {
        path: "/:location/:category/:categoryName",
        name: "FarmPagefilter",
        component: FarmPagefilter,
        meta: {
          title: "FarmPagefilter", // Placeholder title
        },
        beforeEnter: async (to, from, next) => {
          const categoryName = to.params.categoryName;
          const city = to.params.location || "Tamilnadu"; // Default location if none is provided
    
          try {
            // Fetch the JSON file (or this could be an API endpoint)
            // const response = await axios.get('/meta.json');
            const metaData = metaDataJson.data.farmpagefilter.find(
              (item) => item.category_name === categoryName
            );
    
            if (metaData) {
              // Replace {district} with the actual location (city)
              const title = metaData.title.replace("{district}", city);
              const description = metaData.description.replace("{district}", city);
    
              // Update the document title
              document.title = title;
    
              // Update the meta description tag
              const metaDescription = document.querySelector('meta[name="description"]');
              if (metaDescription) {
                metaDescription.setAttribute("content", description);
              }
    
              // Push the updated meta data to the GTM dataLayer
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "metaUpdate",
                pageTitle: title,
                pageDescription: description,
              });
            }
          } catch (error) {
            console.error("Error fetching meta data:", error);
          }
    
          next();
        },
      },
    {
        path: "/ad",
        name: "BottomAd",
        component: BottomAd,
    },
    {
        path: "/VayalAgro-choice_agriculture-equipments",
        name: "EquipmentChoice",
        component: EquipmentChoice,
    },
    {
        path: "/VayalAgro-choice_agriculture-products",
        name: "ProductChoice",
        component: ProductChoice,
    },
    {
        path: "/login",
        name: "LoginAccount",
        component: LoginAccount,
        meta: {
            title: "Login",
            metaTags: [{ name: "Login", content: "vayal Login." }],
        },
    },
    {
        path: "/delete-account",
        name: "AccountDelete",
        component: AccountDelete,
        meta: {
            title: "AccountDelete",
            metaTags: [
                { name: "AccountDelete", content: "vayal AccountDelete." },
            ],
        },
        beforeEnter: (to, from, next) => {
            if (to.query.token) {
                next();
            } else {
                next("/login");
            }
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // If a savedPosition is available, use it (e.g., when using the back/forward browser buttons)
            return savedPosition;
        } else if (to.hash) {
            // If the route has a hash (fragment), scroll to the element with the matching ID
            const element = document.getElementById(to.hash.slice(1));
            if (element) {
                return { el: element, behavior: "smooth" };
            }
        } else {
            // Default behavior: scroll to top of the new page
            return { top: 0, behavior: "smooth" };
        }
    },
});

router.beforeEach((to, from, next) => {
    const newParams = { ...to.params };

    if (
        to.params.market_name &&
        to.params.market_name !== to.params.market_name.toLowerCase()
    ) {
        newParams.market_name = to.params.market_name.toLowerCase();
    }

    if (
        to.params.location &&
        to.params.location !== to.params.location.toLowerCase()
    ) {
        newParams.location = to.params.location.toLowerCase();
    }

    if (
        to.params.product &&
        to.params.product !== to.params.product.toLowerCase()
    ) {
        newParams.product = to.params.product.toLowerCase();
    }
    if (JSON.stringify(newParams) !== JSON.stringify(to.params)) {
        next({
            name: to.name,
            params: newParams,
            query: to.query,
            hash: to.hash,
        });
    } else {
        next();
    }
});

export default router;
