import { defineStore } from "pinia";
// import { fetchData } from "@/components/product"; 

export const useUserStore = defineStore("userStore", {
    state: () => ({
        marketId: "",
        marketPlaceId: "0",
    }),
    actions: {
        updateMarketId(id) {
            console.log("mId changed to ", id);
            this.marketId = id;
        },
        async updateMarketIdByDist(district) {
            // if (!this.location) {
            //   this.searchResults = [];
            //   return
            // }
            console.log("updating mId for...", district);
            try {
                const response = await fetch(
                    `https://vaiyal-app.herokuapp.com/get/location?search=${district}`
                );
                const data = await response.json();

                if (data.status === "success") {
                    // Filter items where "market_name" is present
                    if (data.data.length) {
                        this.marketId = data.data[0].market_id;
                        console.log("mId changed to ", this.marketId);
                    } else {
                        console.log("no districts...", data.data);
                    }
                } else {
                    console.log("Error fetching dists results:", data.msg);
                }
            } catch (error) {
                console.error("Error fetching dists results:", error);
            }
        },
        updateMarketPlaceId(id) {
            console.log("mpId changed to ", id);
            this.marketPlaceId = id;
        },
        async updateMarketPlaceIdByCity(city) {
            // if (!this.location) {
            //   this.searchResults = [];
            //   return
            // }
            console.log("updating mpId for...", city);
            try {
                const response = await fetch(
                    `https://vaiyal-app.herokuapp.com/getcities?type=market&subcategoryid=${this.subCategoryId}&marketid=${this.marketId}`
                );
                const data = await response.json();

                if (data.status === "success") {
                    // Filter items where "market_name" is present
                    if (data.data.length) {
                        data.data.map((place) => {
                            if (place.place.toLowerCase() == city) {
                                this.marketPlaceId = place.market_place_id;
                                console.log(
                                    "mpId changed to ",
                                    this.marketPlaceId
                                );
                            }
                        });
                    } else {
                        console.log("no cities found...", data.data);
                    }
                } else {
                    console.log("Error fetching city results:", data.msg);
                }
            } catch (error) {
                console.error("Error fetching city results:", error);
            }
        },
    },
});

export const useItemStore = defineStore("itemStore", {
    state: () => ({
        categoryId: "",
        subCategoryId: "",
    }),
    actions: {
        async updateItemId(itemName, field = "category_name", mp = false) {
            console.log("itemName", itemName);
            try {
                console.log("$$$updatingItemId...");
                const response = await fetch(
                    `https://vaiyal-app.herokuapp.com/get/lists?search=${itemName}`
                );
                const data = await response.json();
                console.log("item data:", data);
                if (data.status === "success" && data.data.length) {
                    // console.log('data.status', data.status)

                    data.data.map((item) => {
                        console.log("itemName:", itemName.toLowerCase());
                        // console.log('item[field]).toLowerCase', item[field].toLowerCase())

                        if (String(item[field]).toLowerCase().trim() ==
                                itemName.toLowerCase().trim()
                        ) {
                            console.log("itemName matched", item);
                            if (
                                (mp &&
                                    item.category_type == "market price" &&
                                    field == "category_name") ||
                                (!mp && item.category_type != "market price" && field == "category_name")
                            ) {
                                this.categoryId = item.category_id;
                                console.log(
                                    "category_name matched",
                                    this.categoryId
                                );
                            } else if (
                                (mp &&
                                    item.category_type == "market price" &&
                                    field == "sub_category_name") ||
                                (!mp && field == "sub_category_name")
                            ) {
                                this.subCategoryId = item.sub_category_id;
                                console.log(
                                    "sub_category_name matched",
                                    this.subCategoryId
                                );
                            }
                        }
                    });
                } else {
                    console.error(
                        "Error fetching product search results:",
                        data.msg
                    );
                }

                // const resdata = window.comp.fetchData();
                // console.log("resdata in store:", window.comp.fetchData());
                // return resdata;
            } catch (error) {
                console.error("Error fetching product search results:", error);
            } finally {
                console.log("subcat: ", this.subCategoryId);
                console.log("cat: ", this.categoryId);
            }
        },
        async updateMarketCatId(itemName, field = "category_name") {
            console.log("itemName", itemName);
            try {
                console.log("$$$updatingMarketCatId...");
                const response = await fetch(
                    `https://vaiyal-app.herokuapp.com/categorytype`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ categorytype: "market price" }),
                    }
                );
                const data = await response.json();
                console.log("market cat data:", data);
                if (data.status === "success") {
                    // console.log('data.status', data.status)

                    data.data.map((item) => {
                        console.log("mCatName:", itemName.toLowerCase());
                        // console.log('item[field]).toLowerCase', item[field].toLowerCase())

                        if (
                            String(item[field]).toLowerCase() ==
                            itemName.toLowerCase()
                        ) {
                            console.log("itemName matched", item);
                            if (field == "category_name") {
                                this.categoryId = item.category_id;
                                console.log(
                                    "category_name matched",
                                    this.categoryId
                                );
                            } else if (field == "sub_category_name") {
                                this.subCategoryId = item.sub_category_id;
                                console.log(
                                    "sub_category_name matched",
                                    this.subCategoryId
                                );
                            }
                        }
                    });
                } else {
                    console.error(
                        "Error fetching product search results:",
                        data.msg
                    );
                }

                // const resdata = window.comp.fetchData();
                // console.log("resdata in store:", window.comp.fetchData());
                // return resdata;
            } catch (error) {
                console.error("Error fetching product search results:", error);
            } finally {
                console.log("subcat: ", this.subCategoryId);
                console.log("cat: ", this.categoryId);
            }
        },
        updateCatId(id) {
            console.log("cat changed to ", id);
            this.categoryId = id;
        },
        updateSubCatId(id) {
            this.subCategoryId = id;
        },
    },
});
