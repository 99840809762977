<template>
    <div class="popup" v-if="showPopup">
      <!-- Popup content -->
      <div class="popup-content">
        <!-- Your content goes here -->
        <img  v-if='this.img && this.img !== ""' :src=img  loading="lazy"  alt="Icon1" class="pro-icon">
        <img v-else src="../../assets/img/Vayal_Icon-removebg-preview.webp" loading="lazy" alt="Icon" class="logo-icon">
        <p>{{ popupMessage }}</p>
        <p v-if='this.link  !== ""'><a :href= link target="_blank">Click Here</a></p>
        <!-- Clear button -->
        <button @click="closePopup">x</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showPopup: false,
        popupMessage: '',
      };
    },
    methods: {
      closePopup() {
        this.showPopup = false;
      },
      fetchPopup() {
        // Fetch data from the API
        fetch('https://vaiyal-app.herokuapp.com/maintenance?type=popup')
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success' && data.data.isactive) {
            
                this.img = data.data.img;
                this.link = data.data.link;
                this.popupMessage = data.data.msg;
                this.showPopup = true;
              
            } else {
              console.warn('Failed to fetch data:', data.msg);
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },  
    },
    mounted() {
      this.fetchPopup();
    },
  };
  </script>
  
  <style scoped>
  .popup {
    position: fixed;
    top:0%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  
  .popup-content {
    border: 1px solid black;
    background: white;
    width: 50%;
    height: auto;
    border-radius: 8px;
    text-align: center;
    position: relative;
  }
  
  .popup-content .logo-icon {
    margin-top: 5%;
    margin-bottom: 3%;
    width: 100px;
    height: 100px;
  }

   .popup-content .pro-icon {
    margin-top: 5%;
    margin-bottom: 3%;
    width: 500px;
    height: 300px;
    object-fit: cover;
  }
  
  .popup-content p {
    font-size: 20px;
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

   .popup-content p a{
    color: black;
    font-size: 16px;
   }
  
  .popup-content button {
    position: absolute;
    top: 0px;
    right: 3px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #555555;
    font-weight: bold;
  }
  
  @media screen and (max-width: 2560px) {
    .popup-content {
      max-width: 50%;
      width: auto !important;
      max-height: 100%;
      height: auto;
    }
    .popup-content .pro-icon {
    margin-bottom: 3%;
      max-width: 90%;
      width: auto !important;
    height: 500px;
  }
  }
    @media screen and (max-width: 1640px) {
      
  .popup-content {
      max-width: 50%;
      width: auto !important;
      max-height: 100%;
      height: auto;
    }
     .popup-content .pro-icon {
    margin-bottom: 3%;
      width: auto;
    height: 400px;
    object-fit: cover;
  }
  }
  @media screen and (max-width: 1440px) {
    .popup-content {
      max-width: 50%;
      width: auto !important;
      max-height: 100%;
      height: auto;
    }
     .popup-content .pro-icon {
    margin-bottom: 3%;
    width: auto;
    height: 400px;
object-fit: cover;  
}
  }

    @media screen and (max-width: 1080px) {
 .popup-content .pro-icon {
    margin-bottom: 3%;
       width: auto;
    height: 400px;
    object-fit: contain;
  }
    }
  @media screen and (max-width: 768px) {
    .popup-content {
      margin-top: -5%;
     max-width: 50%;
      width: auto !important;
      max-height: 100%;
      height: auto;
    }
    
    .popup-content .pro-icon {
    margin-bottom: 3%;
    width: 300px;
    height: 200px;
    object-fit: contain;
  }

  .popup-content p{
    font-size: 14px;
  }
  }
  @media only screen and (max-device-width: 480px) {
    .popup-content {
      max-width: 80%;
      width: auto !important;
      max-height: 100%;
      height: auto;
    }
    .popup-content .pro-icon {
    margin-bottom: 3%;
    width: 250px;
    height: 300px;
  }

    

  .popup-content p{
    font-size: 14px;
  }
  }

  @media only screen and (max-device-width: 360px) {
.popup-content {
      max-width: 80%;
      width: auto !important;
      max-height: 100%;
      height: auto;
    }
    .popup-content .pro-icon {
    margin-bottom: 3%;
    width: 250px;
    height: 300px;
  }
  }
  </style>
  