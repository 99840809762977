<template>
  <div id="base" v-if="showImage && !isLoginPage">
    <div class="surway">
       <h6 data-tally-open="wQ02R1" data-tally-hide-title="1" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">👋</h6>
    </div>
    <a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="_blank">
      <button class="btn btn-sucuess">
           <span>SELL</span>
      </button>
    </a>
    
  </div>
</template>
 <style scoped>
   
    </style>
<script>
import { en, ta } from "@/translations";

export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue

  data() {
    return {
      showImage: true,
      isLoginPage: true,
      images: {
        long: "https://static8.depositphotos.com/1000580/984/i/950/depositphotos_9840563-stock-photo-colorful-meadow.jpg",
        title: "https://vayalagro.com/img/vayal-new-logo.png",
        ea: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Electronic-Arts-Logo.svg/1024px-Electronic-Arts-Logo.svg.png",
        ps: "https://img.gem-flash.com/images/14907855215718636092.png",
        x: "http://www.iconarchive.com/download/i98465/dakirby309/simply-styled/Xbox.ico"
      }
    };
  },
  created() {
    this.checkLoginPage();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$route': function() {
      this.checkLoginPage();
    }
  },
  methods: {
    takeTheWheel() {
      // Handle button click logic
    },
    checkLoginPage() {
      if (this.$route.name === 'LoginAccount' || this.$route.name === 'AccountDelete') {
        this.isLoginPage = true;
      } else {
        this.isLoginPage = false;
      }
    },
    closeImage() {
      this.showImage = false;
    },
    handleScroll() {
      const footer = document.querySelector('.footer-distributed');
      if (footer) {
        const footerPosition = footer.getBoundingClientRect();
        const base = document.getElementById('base');
        
        if (base) {
          if (footerPosition.top <= window.innerHeight) {
            base.style.display = 'none';
          } else {
            base.style.display = 'block';
          }
        }
      }
    }
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
};
</script>

<style scoped>

   body {
        font-family: system-ui;
        background: #222;
    font-family: sans-serif;
        height: 100vh; 
        display: flex;
        justify-content: right;
        align-items: right;
    }

    h6 {
        font-size: 3vw;
        position: relative; 
        white-space: nowrap;
        animation: wave 2s infinite ease-in-out; 
        cursor: pointer;
        margin-left:20%;
    }

    @keyframes wave {
        0%, 100% {
            transform: rotate(0deg); 
        }
        25% {
            transform: rotate(10deg); 
        }
        50% {
            transform: rotate(-10deg); 
        }
        75% {
            transform: rotate(10deg); 
        }
    }
  #base {
    position: sticky;
    right: 10px;
    top: 0;
    bottom: 0;
    width: 10%;
    margin-left: 85%;
    height: 100px;
    overflow: hidden;
    z-index: 999999;
  }

  .btn {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: bold;
    background: #FE0034;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
    border: none;
    border-radius: 6px;
  }

  .btn:hover {
    background-color: #FE0034;
  }

  a:link,
  a:visited {
    color: azure;
    text-decoration: none;
  }
  @media screen and (min-device-width: 1760px) and (max-device-width: 2560px) { 
 #base {
      
      
      height: 130px;
    }
 }
  @media screen and (max-device-width: 993px) {
    #base {
      position: sticky;
      right: 10px;
      bottom: 0;
      width: 20%;
      margin-left: auto;
      margin-right: auto;
      height: 100px;
      overflow: hidden;
      z-index: 999999;
    }
     h6 {
        font-size: 5vw;
        position: relative; 
        white-space: nowrap; 
        animation: wave 2s infinite ease-in-out; 
        cursor: pointer;
        margin-left: 25%;
    }
  }

  @media (max-width: 480px) {
    #base {
      width: 40%;
      height: 100px;
    }
    h6 {
        font-size: 10vw;
        position: relative; 
        white-space: nowrap; 
        animation: wave 2s infinite ease-in-out; 
        cursor: pointer;
        margin-left: 25%;
    }
    
  }
</style>
