<template>
  <div>
    <div class="containe listdate">
      <div class="colourmaker">
        <div class="tpic-align-cenetr">
        <div class="onplace">
         <h1 class="mx-4">
  {{ selectedLanguage === "en" ? 
    this.$route.params?.categoryName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) : 
    selectedProduct
  }} 
 {{ selectedLanguage === "en" 
  ? translations.marketPrice + (isToday(created) ? ' ' + translations.today : '') 
  : translations.today + ' ' + translations.marketPrice + (isToday(created) ? '' : '') 
}} 
  {{ selectedLanguage === "en" ? 
    (this.$route.params.location?.startsWith('-') ? 
      this.$route.params.location?.split('-')[1]?.replace(/\b\w/g, char => char.toUpperCase()) || 'Tamilnadu' : 
      this.$route.params?.location?.replace(/\b\w/g, char => char.toUpperCase()) || 'Tamilnadu') : 
    selectedDistrict ?? translations.tamilnadu
  }}
  {{ '(' + formatDate(created) + ')' }}
</h1>

        </div>
        <div v-if="content" class="containers content my-3 mx-4">
          <p>{{ content }}</p>
        </div>
      </div>
      <div class="loco searchbox d-md-flex justify-content-center">

        <div class="dist mx-3">
        <h6>{{ translations.getcate }}</h6>
        <select class="selec " v-model="selectedCategory" searchable>
          <option value="0" disabled >{{ translations.selectProduct }}</option>
          <option v-for="category in categories" :key="category.category_id" :value="category.category_id" >
            {{ selectedLanguage === "en" ? category.category_name : category.tn_name }}
          </option>
        </select> 
      </div>
    
      <div class="dist mx-3">
        <h6>{{ translations.selectdist }}</h6>
        <select class="selec " v-model="selectedMarket" searchable :disabled="!markets.length">
          <option value="0" disabled >{{ translations.selectDist }}</option>
          <option v-for="market in markets" :key="market.market_id" :value="market.market_id" 
          >
            {{ selectedLanguage === "en" ? market.market_name : market.tn_name }}
          </option>
        </select> 
      </div>
      <!-- <div v-else>
        <p>No markets available</p>
      </div> -->

      <div class="town mx-3">
        <h6>{{ translations.selecttown }}</h6>
        <select class="selec" v-model="selectedPlace" :disabled="!placeData.length">
          <option value="0">{{ translations.all }}</option>
          <option v-for="place in placeData" :key="place.market_place_id" :value="place.market_place_id">
            {{ selectedLanguage === "en" ? place.place : place.tn_place }}
          </option>
        </select>
      </div>

      <div class="town mx-3">
      <h6>{{ translations.date }}</h6>
      <input 
        type="date" 
        class="form-control date-formate" 
        aria-label="Large" 
        aria-describedby="inputGroup-sizing-sm"
        v-model="created"
        :max="currentDate"
        :min="minDate"
      />
      </div>

      <div class="dist mx-3" @click="searchMarketData">
      <button :disabled="selectedMarket == '0' || selectedCategory == '0'"> Search </button>
      </div>
    </div>


        <div class="table-container text-center mt-5">
          <div v-if="isLoading">
            <!-- Shimmer effect for loading -->
            <div v-for="index in 6" :key="index" class="container">
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
            </div>
          </div>
          <div v-else-if="(sublistData.length || (tumpData.length && !this.$route.params.location) )">
            <div v-for="(date, index) in (sublistData.length ? sublistData : tumpData)" :key="date.uniqueId" class="showdist">
              <!-- Check if it's the 0th element -->
              <h2 v-if="index === 0">
                
                <span v-if="sublistData.length">
                  {{
                    this.searchedPlace != '0' ?
                    selectedLanguage === "en"
                    ? date.place + ','
                    : date.tn_place + ',' : ''
                  }}
                  {{
                    selectedLanguage === "en"
                    ? date.market_name
                    : date.tn_name
                  }},
                  {{ sublistDate(date.created)}}
                </span>
                <span v-else-if="tumpData.length && !this.$route.params.location">Recent Market Price</span>
                <!-- <span v-if="tumpData.length && !Object.keys(this.$route.params.location).length && !sublistData.length">{{ formatDate(date.created) }}</span> -->
              </h2>

              
            </div>
            
                  <div class="d-sm-none">

            <table class="table table-responsive  text-center px-md-5 px-sm-2 px-2 ">
              <thead>
                <tr class="text-nowrap head">
                  <th>{{translations.category}}</th>
                  <!-- <th>{{translations.place}}</th> -->
                  <th>{{translations.price}}/{{translations.quantity}}</th>
                  <!-- <th>{{translations.quantity}}</th> -->
                  
                  <!-- Add more headers as needed -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="date in (sublistData.length ? sublistData : tumpData)" :key="date.uniqueId" class="text-nowrap">
                  <!-- Display relevant information from the response -->
    
                  <td>
                   {{
                      selectedLanguage === "en"
                      ? date.sub_category_name
                      :  date.tn_sub_name
                    }}
                  <br> <small>Id: {{ date.uniqueId }}</small>
                  </td>
                  <!-- <td class="text-nowrap">
                    
                    {{
                      selectedLanguage === "en"
                      ? date.place
                      : date.tn_place
                    }},
                    {{ selectedLanguage === "en"
                      ? date.market_name
                      : date.tn_name
                    }}
                  </td> -->
                  <td> <i class="fa fa-inr" aria-hidden="true"></i>
                    {{ date.price }}/ {{ selectedLanguage === 'en'
                  ? date.quantity_type
                  : date.quantity_type_tamil }} 
                    <br>
                    <router-link class="history-icon" :to="{
                      name: 'AllRecent',
                      params: {
                        ...this.$route.params,
                        name: date.sub_category_name.trim().replace(/\s+/g, '-').toLowerCase() || '',
                        categoryName: date.category_name.replace(/\s+/g, '-').toLowerCase(),
                        location: '-' + date.market_name.replace(/\s+/g, '-').toLowerCase(),
                        city: date.place.replace(/\s+/g, '-').toLowerCase(),
                      },
                    }">{{ translations.history }} &nbsp;
                    <i class="fas fa-chart-line"></i></router-link>
                    
                    </td>
                  <!-- <td>{{ date.quantity }}</td> -->
                  <!-- Add more cells as needed -->
                  
                </tr>
              </tbody>
            </table>
                  </div>
<div class="table-1-web d-none d-sm-block">
          <table class="table table-responsive  text-center px-md-5 px-sm-2 px-2 ">
              <thead>
              
                <tr class="text-nowrap head">
                  <th>{{translations.category}}</th>
                  <th >{{translations.selectdist}}</th>
                  <th >{{translations.city}}</th>
                  <th>{{translations.price}}/{{translations.quantity}}</th>
                  <!-- <th>{{translations.quantity}}</th> -->
                  
                  <!-- Add more headers as needed -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="date in (sublistData.length ? sublistData : tumpData)" :key="date.uniqueId" class="text-nowrap">
                  <!-- Display relevant information from the response -->
    
                  <td>
                   {{
                      selectedLanguage === "en"
                      ? date.sub_category_name
                      :  date.tn_sub_name
                    }}
                  <br> <small>Id: {{ date.uniqueId }}</small>
                  </td>
                  <td class="text-nowrap">
                    {{ selectedLanguage === "en"
                      ? date.market_name
                      : date.tn_name
                    }}
                  </td>
                  <td class="text-nowrap">
                    {{
                      selectedLanguage === "en"
                      ? date.place
                      : date.tn_place
                    }}
                  </td>
                  <td> <i class="fa fa-inr" aria-hidden="true"></i>
                    {{ date.price }}/ {{ selectedLanguage === 'en'
                  ? date.quantity_type
                  : date.quantity_type_tamil }} 
                    <br>
                    <router-link class="history-icon" :to="{
                      name: 'AllRecent',
                      params: {
                        ...this.$route.params,
                        name: date.sub_category_name.trim().replace(/\s+/g, '-').toLowerCase() || '',
                        categoryName: date.category_name.replace(/\s+/g, '-').toLowerCase(),
                        location: '-' + date.market_name.replace(/\s+/g, '-').toLowerCase(),
                        city: date.place.replace(/\s+/g, '-').toLowerCase(),
                      },
                    }" target="_blank">{{ translations.history }} &nbsp;
                    <i class="fas fa-chart-line"></i></router-link>
                    
                    </td>
                  <!-- <td>{{ date.quantity }}</td> -->
                  <!-- Add more cells as needed -->
                  
                </tr>
              </tbody>
            </table>
</div>
          </div>
          <div v-else>
             <div class="nodata-img">
              <img class="" src="../assets/img/why/emptypro.jpg" loading="lazy" alt="empty">
            </div>
              <h2 class="my-3 no-data">
                {{ translations.no_data_market }} 
               </h2>
               
               <p class="no-data-para">{{ translations.no_data_found }}</p>
            <!-- <h2 class="my-3 no-data">{{`${$route.params.product?.replaceAll('-',' ')} will be coming soon in ${this.$route.params?.city?.replaceAll('-',' ') || $route.params.location?.replaceAll('-',' ')}!`}} <br> </h2>-->
              <!-- <button class="mt-5 btns-soon my-2 px-4 py-2"><router-link to="/">Go Home</router-link> </button>  -->
            
          </div>

            <h2 class="todays-head" v-if="this.$route.params.categoryName && Newmarket.length > 1">
  {{ translations.todays }} 
  {{ selectedLanguage === 'en' ? 
    this.$route.params?.categoryName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) : 
    selectedProduct 
  }} 
  {{ translations.marketPrices1 }} {{ translations.tamilnadu }}
</h2>
<div class="market-container">

  <div
    v-for="(market, index) in Newmarket"
    :key="market.market_id"
    :value="market.market_id"
    class="inter-links"
  >
    <span
      v-if="this.$route.params.categoryName && this.$route.params.location !== '-' + market.market_name.replace(/\s+/g, '-').toLowerCase()"
      @click="navigateToMarketPrice(market)"
    >
    {{ selectedLanguage === 'en' ? market.market_name : market.tn_name }} {{ selectedLanguage === 'en' ? 
      this.$route.params?.categoryName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) : 
      selectedProduct
      }} {{ translations.marketPrice  }}
      
      <span v-if="index !== Newmarket.length - 1"> | </span>
    </span>
  </div>
</div>

        </div>
      </div>
    </div>

    <nav v-if="sublistData.length">
      <ul class="pagination">
        <!-- First Page Button -->
        <li :class="{ disabled: currentPage === 1 }">
          <a @click="onPageChange(1)" aria-label="First">
            <span aria-hidden="true">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </span>
          </a>
        </li>

        <!-- Previous Page Button -->
        <li :class="{ disabled: currentPage === 1 }">
          <a @click="onPageChange(currentPage - 1)" aria-label="Previous">
            <span aria-hidden="true">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </span>
          </a>
        </li>

        <li :class="{ active: currentPage === pageNumber }" v-for="pageNumber in pageNumbers" :key="pageNumber">
          <a @click="onPageChange(pageNumber)">{{ pageNumber }}</a>
        </li>
        <!-- Next Page Button -->
        <li :class="{ disabled: currentPage === totalPages }">
          <a @click="onPageChange(currentPage + 1)" aria-label="Next">
            <span aria-hidden="true">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </span>
          </a>
        </li>

        <!-- Last Page Button -->
        <li :class="{ disabled: currentPage === totalPages }">
          <a @click="onPageChange(totalPages)" aria-label="Last">
            <span aria-hidden="true">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </span>
          </a>
        </li>
      </ul>
    </nav>
     <div class="disclaimer">
      <p class="text-center my-3 container" v-if="disclaimer"><strong>Disclaimer:</strong> {{ disclaimer }}</p>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { en, ta } from "@/translations";
import { useItemStore, useUserStore } from "@/stores/userStore";
import { mapActions, mapState } from "pinia";

export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue

  data() {
    return {
      marketName: null,
      categoryName: null,
      sublistData: [],
      isLoading: true,
      place: null,
      totalItems: 0,
      itemsPerPage: 10, // Example: set the default number of items per page
      currentPage: 1,
      totalPages: 0, // Add this property to store the total number of pages
      selectedMarket: '0',
      markets: [],
      Newmarket:[],
      tumpData:[],
      selectedPlace: "0",
      placeData: [],
      selectedCategory: '0',
      categories: [],
      dates: [],
      chooseDate:'',
      // created: this.selectedDate || new Date().toISOString().split('T')[0],
      isFreshPage: this.$route.params.location ? true : false,
      created: new Date().toISOString().split('T')[0],
      currentDate: new Date().toISOString().split('T')[0],
      minDate: this.getMinDate(),
      selectedProduct:'',
      selectedDistrict:'',
      searchedPlace: '0',
      subListDataFetched: false,
    };
  },
  computed: {
    ...mapState(useItemStore, ['categoryId', 'subCategoryId']),
    ...mapState(useUserStore, ['marketId', 'marketPlaceId']),
    selectedDate() {
      return this.$router.history?.state?.selectedDate || false;
    },
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    formatDate() {
      return date => moment(date).format("DD-MM-YYYY");
    },

    sublistDate(){
      return date => date.split('/').join('-');
    },
    pageNumbers() {
      const visiblePageCount = 3; // Number of page numbers to display
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(visiblePageCount / 2)
      );
      const endPage = Math.min(
        this.totalPages,
        startPage + visiblePageCount - 1
      );

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    }
  },
  beforeCreate() {
    
    if (!Object.keys(this.$route.params).length) {
      console.log('setting empty params...')
      this.$route.params = {
        location : '',
        categoryName : '',
        city: '',
        created: '',
        categoryId: ''
      }
      this.selectedCategory = '0';
      this.selectedMarket = '0';
      this.selectedPlace = '0';
    }
    if (this.selectedDate) {
      this.created = this.selectedDate
      console.log('created set from state!')
    }
  },
  watch: {
    categoryId(newVal, oldVal) {
      console.log('Category ID changed:', newVal);
      if(newVal !== oldVal) {
        // this.fetchMarketData();
        // this.updateMarketPlaceId(this.$route.params?.city?.replace(/-/g,' '),this.categoryId,this.marketId)

      } 
      // this.categoryId = newVal; 
    },
    subCategoryId(newVal) {
      console.log('SubCategory ID changed:', newVal);
    },
    marketId(newVal, oldVal) {
      console.log('Market ID changed:', newVal);
      if(newVal !== oldVal && this.marketId != '0' && this.categoryId != '0') this.fetchPlaceData();
      
    },
    marketPlaceId(newVal, oldVal) {
      console.log('Marketplace ID changed from:',oldVal, ' to ', newVal);
      
      // if(this.isFreshPage && newVal !== oldVal && this.categoryId != '0' && this.marketId != '0') {
      //   // this.selectedPlace = newVal;
      //   this.fetchSublistData();
      //   console.log('sublist function condition success:')
      //   this.isFreshPage = false;
      // } 
      // else console.log('sublist function condition failed:'+ 'cat:' + this.categoryId + 'mId:' + this.marketId);
    },
    // Watch for changes in the route parameters
    '$route.params': {
    handler(newParams, oldParams) {
      console.log('route params watcher called...')
this.updateMetaTags();
      this.handleRouteChange(newParams, oldParams);
    },
    deep: true,
    immediate: true,
  },
  selectedMarket: {
    handler(newVal, oldVal) {
      console.log('selectedMarket watcher called... new:',newVal, 'old:', oldVal)
      console.log('cat:',this.selectedCategory, 'mId:', this.selectedMarket, 'mpId:', this.selectedPlace);
      this.updateMarketId(this.selectedMarket);
      if(!this.subListDataFetched && Object.keys(this.$route.params).length && this.selectedCategory != '0' && this.selectedMarket != '0' && !this.$route.params?.city != '0') {
        console.log('marketId changed sublist called: ')
        this.fetchSublistData()
        this.isFreshPage = false;
      }
      this.currentPage = 1; // Reset the current page to 1 when the market changes
      if (this.selectedMarket != '0') {
      this.selectedPlace = '0';
      this.fetchPlaceData();

      }
    },
    immediate: true,
},
  selectedPlace: {
    handler(newPlace, oldPlace ) {
      console.log('selectedPlace watcher -> old:',oldPlace, 'new:', newPlace)
      console.log('cat:',this.selectedCategory, 'mId:', this.selectedMarket, 'mpId:', this.selectedPlace);
      this.updateMarketPlaceId(newPlace);
      console.log('marketPlaceId changed from selectedPlace: ',this.marketPlaceId)
      
      if(!this.subListDataFetched && Object.keys(this.$route.params).length && newPlace !== oldPlace && this.categoryId && this.marketId) {
        // this.selectedPlace = newVal;
        this.fetchSublistData();
        console.log('sublist function condition success:')
        this.isFreshPage = false;
      } 
      else console.log('sublist function condition failed:'+ 'cat:' + this.categoryId + 'mId:' + this.marketId + this.subListDataFetched);
      // this.handleRouteChange();
      this.currentPage = 1; // Reset the current page to 1 when the place changes
  
    },
    immediate: true,
  },
  selectedCategory: {
    
    handler() {
      console.log('selectedCategory in watchers: ',this.selectedCategory)

      console.log('cat:',this.selectedCategory, 'mId:', this.selectedMarket, 'mpId:', this.selectedPlace);
      if(this.selectedCategory!= '0'){
        this.fetchMarketData()
      }

      this.updateCatId(this.selectedCategory)
    
    // this.handleRouteChange();
    // this.handleCatChange();
    }
  },
  created: {
    handler() {
      console.log('created changed: ')
      if(this.selectedCategory != '0' && this.selectedMarket != '0') {
        console.log('date changed sublist called: ')
        this.fetchSublistData()
        this.isFreshPage = false;
      }
    }
  }
 
  },
  beforeUnmount() {
    this.sublistData = [],
    // this.selectedMarket = '0',
    this.markets = [],
    this.tumpData = [],
    // this.selectedPlace = "0",
    this.placeData = [],
    // this.selectedCategory = '0',
    this.categories = [],
    this.dates = []
  },
//   beforeRouteUpdate(to, from, next) {
//     console.log('to:',to)
//     console.log('from:',from)
//   if (to.params.categoryName !== from.params.categoryName) {
//     console.log('Route is updating with new category name:', to.params.categoryName);
//     this.handleCatChange();
//   }
//   next();
// },
  async created() {
    this.fetchCategoryData();
    if (this.selectedCategory) {
      console.log('created: districts called')
      await this.fetchMarketData();
    }
    if (this.selectedCategory != '0' && this.selectedMarket != '0') {
      console.log('created: sublist called')
      this.fetchSublistData();
    }

    if(!this.$route.params.location){
     await this.fetchInitData();
    }
        this.updateMetaTags(); // Initialize meta tags on component creation

  },
  mounted() {
   
    console.log('mounted called',this.created );
    this.marketName = this.$route.params?.location;
    this.categoryName = this.$route.params?.categoryName?.replace(/-/g, ' ');
  },
  methods: {
    ...mapActions(useItemStore, ['updateItemId','updateMarketCatId','updateSubCatId','updateCatId']),
    ...mapActions(useUserStore, ['updateMarketId','updateMarketIdByDist','updateMarketPlaceId','updateMarketPlaceIdByCity']),
    redirectToContact() {
      // Use the Vue Router's `push` method to navigate to the "/contact" page
      this.$router.push("/contact");
    },
    onPageChange(pageNumber) {
      if (
        pageNumber < 1 ||
        pageNumber > this.totalPages ||
        pageNumber === this.currentPage
      ) {
        return;
      }

      // Update the current page
      this.currentPage = pageNumber;

      // Fetch data for the new page
      this.fetchSublistData();
    },
   navigateToMarketPrice(market) {
      const categoryName = this.$route.params.categoryName;
      const district = market.market_name;
    
      this.$router.push({
        name: "pricesublist",
        params: {
          categoryName: categoryName,
          location: `-${district}`,
        },
      });
    },
   
    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth" // You can use "auto" for immediate scrolling
      });
    },
    async handleRouteChange(newParams = null, oldParams = null) {

      console.log('newParams:',newParams, 'oldParams:', oldParams);
      if(!Object.keys(newParams).length) {
        console.log('resetting filters...')
        this.selectedCategory = '0';
        this.selectedMarket = '0';
        this.selectedPlace = '0';
        this.fetchInitData()
        this.sublistData = []
      } else {
      await this.fetchMarketData();
      if(this.selectedCategory != '0' && this.selectedMarket != '0') {
        this.fetchSublistData();
      }
      }

    console.log('route change triggered! -> cat:',this.selectedCategory, 'mId:', this.selectedMarket, 'mpId:', this.selectedPlace);


    // if(newParams?.categoryName !== oldParams?.categoryName) {
      // this.isFreshPage = true
      this.fetchCategoryData();
    // }

    // if(this.selectedCategory != '0' && this.selectedMarket != '0') {
    //     console.log('route changed sublist called: ')
    //     this.fetchSublistData()
    //     this.isFreshPage = false;
    //   }
    //   this.categoryId = this.$route.params?.categoryId;
    // this.marketId = this.$route.params?.marketId;
    // this.place = this.$route.params?.place;
    // this.updateMarketId(this.marketId)
    // this.updateMarketPlaceId(this.$route.params?.city.replace(/-/g,' '),this.categoryId,this.marketId)
    
    },
    // async handleCatChange() {
    //   this.$route.params.categoryName = this.selectedCategory?.replace(/\s+/g,'-')
    //   let params = {
    //     ...this.$route.params,
    //     categoryName:  this.selectedCategory?.replace(/\s+/g,'-')
    //   }
      
    // console.log('params aft cat change:', params)
    // this.$router.push({
    //   name: 'pricesublist',
    //   params: params
    // });
   
    // console.log('cat change triggered! -> cat:',this.categoryId, 'mId:', this.marketId, 'mpId:', this.marketPlaceId);
    // },
    getMinDate() {
      const today = new Date();
      today.setDate(today.getDate() - 30); // Subtract 30 days
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    isToday(date) {
      const today = new Date();
      const todayString = today.toISOString().split('T')[0];
      return date === todayString;
    },
    searchMarketData() {
      console.log('searchMarketData called...')
       console.log('location:', this.$route.params?.location)
      // if (this.$route.params?.location && this.$route.params?.location[0] == '-') {
        
        // console.log('loc[0]:', this.$route.params?.location[0])
      // }
      // this.$route.params?.categoryName = this.selectedCategory?.replace(/\s+/g,'-')
      var params = {}
      params = {
        ...this.$route.params,
      }
  this.Newmarket = [];
      console.log('this.categories:',this.categories)

      for (let cat of this.categories) {
        if (cat.category_id == this.selectedCategory) {
          params.categoryName = cat?.category_name?.replace(/\s+/g,'-')?.toLowerCase()
          console.log('setting cat name in params:', params.categoryName)
          break;
        }
      }

      for (let district of this.markets) {
        if (district.market_id == this.selectedMarket) {
          params.location = district?.market_name?.replace(/\s+/g,'-')?.toLowerCase()
          console.log('setting location in params:', params.location)
          break;
        }
      }

      if (this.selectedPlace != '0') {
        for (let city of this.placeData) {
          if (city.market_place_id == this.selectedPlace) {
            params.city = city?.place?.replace(/\s+/g,'-')?.toLowerCase()
            console.log('setting city in params:', params.location)
            break;
          }
        }
      } else params.city = ''
      params.location = params.location && params.location[0] == '-' ? params.location : `-${params.location}`,
      this.isFreshPage = true;

    console.log('params aft cat change:', params)
    // this.isFreshPage = true
      
      // if (this.$route.name == 'marketPrice') {
        this.$router.push({
          name: 'pricesublist1',
          params,
          state: {
            selectedDate : this.created
          }
        });
      // } else {
      //   this.fetchCategoryData();
      //   this.fetchMarketData();
      //   this.fetchSublistData();
      // }
    
    },
    storeIds(data) {
      data.sub_category_id ? this.updateSubCatId(data.sub_category_id) : this.updateCatId(this.selectedCategory);
      // this.updateMarketIdByDist(data.market_name)
      // this.updateMarketPlaceIdByCity(data.place)
      console.log('data stored from mId:',this.selectedMarket,'mpId:',this.selectedPlace,'catId:',this.selectedCategory,'sId:',data.sub_category_id)
      console.log('data stored mId:',this.marketId,'mpId:',this.marketPlaceId,'catId:',this.categoryId,'sId:',this.subCategoryId)
    },
    fetchCategoryData() {
      const data = {
        categorytype: 'market price'
      };
      // console.log('fetching category data...')

      this.isLoading = true; // Set isLoading to true to show the loading spinner

      axios
        .post("https://vaiyal-app.herokuapp.com/categorytype", data)
        .then((response) => {
          // this.categories = response.data.data;
          if (response.data.status == 'success') {
            // console.log("Post response:", this.categoryData);
            this.categories = []
            response.data.data.map((category) => {
            if(category.isdatafound) {
              this.categories.push(category)
              if (category.category_name.replace(/\s+/,'-').toLowerCase() == this.$route.params?.categoryName) {
                this.selectedCategory = category.category_id
                this.selectedProduct = category.tn_name
                console.log("selectedCategory converted from route:", this.selectedCategory)
              }
            }
          })
          //TODO: if cat in params, run this loop.
          for (let category of this.categories) {
            if (category.category_name.replace(/\s+/,'-').toLowerCase() == this.$route.params?.categoryName) {
              this.selectedCategory = category.category_id
              this.selectedProduct = category.tn_name
              console.log("selectedCategory converted from route:", this.selectedCategory)
              break;
            }
          }
          // localStorage.setItem("categoryData", JSON.stringify(this.categoryData));

          this.isLoading = false; // Set isLoading back to false once the data is loaded
          this.$nextTick(() => {
            const container = this.$refs.categoryContainer;
            if (container) {
              container.scrollIntoView({ behavior: "smooth" });
            }
          });
          }
        })
        .catch((error) => {
          console.error("Post error:", error);
          this.isLoading = false; // Set isLoading back to false in case of an error
        });
    },
   async fetchMarketData() {
  try {
    if (this.selectedCategory != 0) {
      console.log("fetching districts...");
      const apiUrl = `https://vaiyal-app.herokuapp.com/getdistrict?type=market&categoryid=${this.selectedCategory}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      
      if (data.status === "success") {
        this.markets = data.data;
        console.log("this.markets", this.markets);

        // Store this.Newmarket if it's the first time hitting the API
        if (!this.Newmarket.length) {
          this.Newmarket = [...this.markets];
        }

        let change;
        this.markets.map(dist => {
          if (this.$route.params.location.split('-')[1].replace(/-/g, ' ') === dist.market_name.toLowerCase()) {
            this.marketId = dist.market_id;
            this.selectedMarket = dist.market_id;
            change = true;
            this.selectedDistrict = dist.tn_name;
            console.log("marketId set by old function...", this.marketId, change);
          }
        });

        if (!change) {
          console.log('dist not matched in selected cat:', change);
          this.selectedMarket = '0';
        }
      } else {
        console.error("API response error:", data.msg);
      }

      this.fetchPlaceData();
    }
  } catch (error) {
    console.error("Error fetching market data:", error);
  }
}
,

async fetchInitData(){
  fetch("https://vaiyal-app.herokuapp.com/get/recentlist?type=market")
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          this.tumpData = data.data
          console.log("123AAAAA",this.tumpData);
        } else {
          console.error("Failed to fetch recent product data:", data.msg);
        }
      })
      .catch(error => {
        console.error("Error fetching recent product data:", error);
      });
},
  async fetchPlaceData() {
    if (this.selectedMarket) {
      try {
        console.log("fetching cities...");
        
        const apiUrl = `https://vaiyal-app.herokuapp.com/getcities?type=market&categoryid=${this.selectedCategory}&marketid=${this.selectedMarket}`;
        const response = await fetch(apiUrl);

        const data = await response.json();
        if (data.status === "success") {
          this.placeData = [];
          this.placeData = data.data;
          let change;
          for (let i of data.data) {
            if (i.place.toLowerCase() == this.$route.params?.city?.replace(/-/g,' ')) {
              this.selectedPlace = i.market_place_id
              this.updateMarketPlaceId(i.market_place_id)
              console.log('selectedPlace in old func: ', this.selectedPlace)
              change = true;
            }
          }
          if(!change) this.selectedPlace = '0'
          console.log('placeData: ', this.placeData)
        } else {
          console.error("API response error:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching place data:", error);
      }
    } else {
      this.placeData = []; // Reset placeData if no market is selected
      this.selectedPlace = "0"; // Reset selectedPlace
    }
  },

    fetchSublistData() {
      console.log('sublist function called...')
      this.isLoading = true;

      const apiUrl = `https://vaiyal-app.herokuapp.com/getplacemarket?page=${this.currentPage}`;
      const payload = {
        categoryid: this.selectedCategory,
        marketid: this.selectedMarket,
        marketplaceid: this.selectedPlace,
        created: this.created
      };
      // to find which place data is showing
      this.searchedPlace = this.selectedPlace
      console.log('payload',payload)

      axios
        .post(apiUrl, payload)
        .then(response => {
          console.log("response",response)
          if (response.data.status === "success") {
            this.subListDataFetched = true;
            this.sublistData = response.data.data.Dates;
            this.totalItems = response.data.data.totalItems;
            this.totalPages = response.data.pages; // Use the correct property name here
            this.itemsPerPage = response.data.data.itemsPerPage;
            this.content = response.data.data.content;
          this.disclaimer = response.data.data.disclaimer;
            
          } else {
            console.error("API Error:", response.data.msg);
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async updateMetaTags() {
      const categoryName = this.$route.params.categoryName;
      const pageName = "pricesublist";
      const city = this.$route.params.city;
      const district = this.$route.params.location?.split('-')[1] || "Tamilnadu";
      const created = this.created;

      // Function to format date as "DD-MM-YYYY"
      const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
  };

  const formattedCreatedDate = formatDate(created);
  console.log("updateMetaTags", formattedCreatedDate);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const capitalizedCity = capitalizeFirstLetter(city || '');
  const capitalizedDistrict = capitalizeFirstLetter(district || "");

  try {
    const response = await axios.get("https://vaiyal-app.herokuapp.com/meta", {
      params: {
        category_name: categoryName,
        page_name: pageName,
      },
    });

    const metaData = response.data.data;

    if (metaData) {
      const title = metaData.title.replace('{city}', this.$route.params.city?capitalizedCity + "," : "" )
        .replace("{district}", capitalizedDistrict);
      document.title = title;

      const description = metaData.description.replace("{city}", this.$route.params.city?capitalizedCity + "," : ""  )
        .replace("{district}", capitalizedDistrict)
        .replace("{date}", formattedCreatedDate);
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
      const head = metaData.title
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict);
       const metaHead = document.querySelector(
                        'meta[name="title"]'
                    );
                    if (metaHead) {
                        metaHead.setAttribute("content", head);
                    }
    }
  } catch (error) {
    console.error('Error fetching meta data:', error);
  }
}

  },
  beforeUpdate() {
    // Scroll to the top every time before the component updates
    this.scrollToTop();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap');
body{
  font-family: 'Mukta';
}
.header h2 {
  justify-content: center;
  text-align: center;
  font-size: 60px;
  padding: 100px 0px 10px 0px;
  color: rgba(199, 234, 199, 0.9);
  text-transform: capitalize;
}

.product-item {
  margin: 50px 0px;
}

.header h5 {
  justify-content: center;
  text-align: center;
  font-size: 30px;
  margin: 10px 0px 10px 0px;
  color: #ffffff;
}

.colourmaker h6 {
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: rgba(0, 128, 0, 0.9);
  margin-block: 20px;
}

.contactre {
  margin: 90px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contactre h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
}

.contactre button {
  padding: 0.3rem 1rem;
  margin-top: 20px;
  margin-bottom: 50px;
  border: 1px solid rgba(255, 255, 255, 0.9);

  border-radius: 6px;
  background-color: transparent;
  color: #fff;
}

.custom-button4:hover {
  background: rgba(255, 255, 255, 0.5);
  color: black;
  transition: background-color 0.3s;

}

.custom-button4:not(:hover) {
  transition: background-color 0.3s;
}

.breadcrumb {
  justify-content: center;
  margin: 30px 0px 50px 0px;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: rgb(231, 221, 221);
}
  


.breadcrumb-item a {
  text-decoration: none;
  color: rgb(231, 221, 221);
}

.breadcrumb-item a:hover {
  color: #888;
}

.listdate {
  margin-top: 30px;
  margin-bottom: 50px;
}

.date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 10px 0px;
  padding: 7px 15px 0px 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.div {
  display: flex;
}

.date h5 {
  font-size: 14px;
  color: rgba(0, 128, 0, 0.9) ;

}

.created {
  color: rgba(0, 128, 0, 0.9) ;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  padding: 50px;
}

.nodata img {
  width: 600px;
}

.colourmaker .date {
  position: relative;
  text-decoration: none;
}

.colourmaker .date::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(0, 128, 0, 0.9);

  z-index: -1;
}

.colourmaker .date:nth-child(odd)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(0, 128, 0, 0.9);

  z-index: -1;
}

.colourmaker .date:nth-child(even)::before {
  background-color: rgba(145, 155, 5, 0.9);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;

  z-index: -1;
}

.material-symbols-outlined {
  font-size: 14px;
  color: rgba(0, 128, 0, 0.9);
  padding-right: 5px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: green;
}

.loading-spinner i {
  font-size: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.price-link {
  text-decoration: none;
  color: black;
}

.price-link:hover {
  color: rgb(133, 133, 3);
}

.onplace {
  display: flex;
  justify-content: space-between;
}
.onplace h1{
  font-size:28px;
  color:#71A600;
}
.marketname {
  display: flex;
}

.marketname p {
  font-size: 12px;
  padding-top: 4px;
  padding-left: 10px;
  color: rgba(145, 155, 5, 0.9);
}


.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 30px;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
}

.pagination a {
  display: block;
  font-size: 12px;
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.5s ease;
}

.pagination a:hover {
  background: #71A600;
}

.pagination li.active a {
  background: #71A600;
  color: #fff;
}

.pagination li.disabled a {
  pointer-events: none;
  opacity: 0.6;
}

@media screen and (max-device-width: 600px) {
  .onplace {
    display: block;
  }

  .listdate {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .header {
    min-height: 50vh;
  }

  .header h2 {
    justify-content: center;
    text-align: center;
    font-size: 60px;
    padding: 50px 0px 10px 0px;
    color: rgba(199, 234, 199, 0.9);
    text-transform: capitalize;
  }

  .nodata img {
    width: 300px;
  }
  .table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.tpic-align-cenetr{
 width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}

}

@media screen and (max-device-width: 400px) {
  .onplace {
    display: block;
  }
.tpic-align-cenetr{
   width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}
  .listdate {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .date h5 {
    font-size: 12px;
  }

  .nodata img {
    width: 300px;
  }

 
.table th{
  background: #71A600;
  color: #fff;
  font-size: 14px;
      padding:10px !important;

}
.table td{
  background: #f9ffed;
    padding:2%  !important;

}

.history-icon{
font-size: 12px !important;
}


.tpic-align-cenetr{

margin-left: 0;
margin-right: 0;}
}
.disclaimer p{
  font-size: 14px;
}
.tpic-align-cenetr{
   width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.content p{

  font-size: 14px;
  font-weight: 600;
}


.table th {
  background-color: #f2f2f2;
  color: #16123f;
}

td a {
  text-decoration: none;
  color: green;
}

td a:hover {
  color: darkgreen;
}

td small {
  color: gray;
}

.table-container {
  justify-content: center;
  align-items: center;
  width: 100%;

}

.table {
  border-collapse: collapse;
  margin-top: 20px;
    margin-left: auto;
  margin-right: auto;
}

.table th,
.table td {
  border-top: 1px solid #71A600;
  border-bottom: 1px solid #71A600;
  padding: 10px 40px;
  text-align: left;
  width: 100%;
}
.table th{
  background: #71A600;
  color: #fff;
}
.table td{
  background: #f9ffed;
}

.shimmer-effect {
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-bottom: 10px;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

h1 span {
  color: green;
}

.shimmer-line {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  height: 15px;
  margin-bottom: 10px;
}

.shimmer-line {
  height: 20px;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

.related {
  background: rgb(139, 211, 139);
  color: #16123f;
  font-weight: bold;
}
.showdist h2{
  font-size: 20px;
  color:#71A600;
  font-weight:600;
}

.history-icon{
  font-size: 14px;
}

.table-1-web{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

select.selec {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../assets/img/Icons/arrow_drop.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding: 8px 30px 8px 8px;
    border: 1px solid #ccc;
    border-radius:0 !important ;
    height:38px !important;
    box-shadow: none !important;
  }
  
  select.selec:focus {
    outline: none;
    border-color: #007bff;
  }

.loco {
    padding: 30px 20px;
    height: 100%;
    border-radius: 6px;
  }
  
  .searchbox h6 {
    font-size: 14px;
    color: rgba(70, 67, 67, 0.8);
  }
  
  .searchbox select {
    width: 100%;
    min-width: 200px;
    height: 40px;
    padding: 0px 10px;
    color: rgba(70, 67, 67, 0.8);
    border: 0.5px solid rgba(163, 159, 159, 0.8);
  }

select:disabled {
  background-color: #f5f5f5;
  color: #999; 
  border: 1px solid #ddd;
  cursor: not-allowed; 
  opacity: 0.6; 
}
  
  .dist,
  .town {
    margin: 20px 0px;
    
  }

  .dist button {
    vertical-align: bottom;
    width: 100px;
    margin-top: 56%;
    height: 40px;
    background-color: #71a600;
    border: 1px solid #71a600;
    color: white;
    border-radius: 2px;
  }

  .date-formate{
    border-radius: 0 !important;
    border: 1px solid #AABBCC;
    box-shadow: none !important;
    }
    @media (min-width: 300px) and (max-width: 767px) {
  .dist button {
    margin-top: 3% !important;
    width: 100% !important;
    margin-right: auto;
    margin-left: auto;
    background-color: #71a600;
    border: 1px solid #71a600;
    color: white;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .table-1-web {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
.tpic-align-cenetr{
 width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}
  .dist,
  .town {
    margin: 20px 10px !important;
  }

 
.searchbox select {
  width: 100%;
  min-width: 130px;
  padding: 0px 10px !important;
    font-size: 14px;

}
}
.market-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 5px; 
  text-align:center ;
  justify-content: center;
   width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.inter-links span{
    white-space: nowrap; /* Prevents the text from wrapping */
  text-align:center ;
  font-weight: 600;
  cursor: pointer;
    color: #71A600;
}
.inter-links span:hover{
    color: #ffc971;
  font-weight: 600;
}

.todays-head{
  font-size: 18px;
  color: black !important;
}

.no-data {
  font-size: 20px;
  font-weight: 600;
}

.no-data-para{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.nodata-img{
  margin-top: -5%;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.nodata-img img{
  width: 100%;
}

</style>
