<template>
  <div class="product-details">
    <!-- <BottomAd :selectedLanguage="selectedLanguage" /> -->
    <div class="pagelink d-flex py-5">
      <div class="container-fluid pt-5 text-center" v-if="product">
        <h1 class="text-uppercase"  v-if="product">
          {{ product.business_name }}
        </h1>
        <h6 class="text-uppercase">
           <router-link :to=" `/${this.$route.params.location}/agriculture-equipments` "> {{ translations.farmEquipments }}</router-link>
          <i class="fa fa-angle-right ps-1 pe-2" aria-hidden="true"></i>
          <!-- FIXME: add location as dynamic in this router link -->
           <router-link v-if="product.sub_category_id != '0'" :to="{ name: 'FarmPagefilter', params: { categoryId: product.category_id, categoryName: product.category_name.replace(/\s/g, '-').toLowerCase(), category: 'agriculture-equipments', location: this.$route.params.location } }">
              {{ selectedLanguage === 'en' ? product.category_name : product.tn_category }}
            </router-link>
           <i class="fa fa-angle-right ps-1 pe-2" aria-hidden="true" v-if="product.sub_category_id != '0'"></i>
         <router-link @click="navigateToAllList" to="">
  {{
    selectedLanguage === 'en'
      ? (product.sub_category_name.trim() !== '' ? product.sub_category_name : product.category_name)
      : (product.tn_sub_name.trim() !== '' ? product.tn_sub_name : product.category_name)
  }}
</router-link>

        </h6> 
      </div>
    </div>
<div class="full">
  <div class="containers detail my-md-4 mx-md-5 mx-sm-2">
    <div v-if="product">
      <div class="text-end" >
       
        <img class="delivery bg-success rounded-circle" @mouseover="showDeliveryMessage = true" @mouseleave="showDeliveryMessage = false" v-if="product.delivery" src="../assets/img/delivery2.png" loading="lazy" alt="delivery">
        <img v-else>
        <div v-if="showDeliveryMessage" class="delivery-above bg-success rounded text-light text-end m-0 px-2">
          Delivery Available
      </div>
      </div>
    
      <div class="row detailgrid ">
        <div class="col-md-5 col-lg-5 col-sm-12 imageleft mt- p-0">
          <div class="mainimage p-0 mb-3 text-center">
            <img class="p-0 m-0" v-if="product.images && product.images.length > 0" :src="product.images[selectedImageIndex]" loading="lazy" :alt="`Proudct: ${product.name}`" @click="openImage" @error="handleGalleryImageError" />
            <img class="p-0 m-0" v-else src="../assets/img/drive/Empty Image.webp" loading="lazy" :alt="`Proudct: ${product.name}`"/>
          </div>
          <div class="imageleft">
            <div class="image-row d-flex">
              <div v-for="(image, index) in product.images" :key="index" @click="selectImage(index)">
                <img :src="image" loading="lazy" :alt="`Proudct: ${product.name}`" @error="handleThumbnailError" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-lg-7 col-sm-12 contentright mt- mx-0  ">
          <div class="d-flex justify-content-between  m-0 p-0  ">
            <div class="product-container mx-0 my-2 p-0  ">
              <h2 class="product-name m-0 pe-1 ">
                {{ product.business_name }}
                <span v-if="product.user_type !== undefined && product.user_type !== ''"  class="user-type">
                  <small class="text-uppercase text-nowrap prices">
                    <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17 18"
                      fill="none">
                      <path
                        d="M8.3653 8.11859C8.29706 8.11176 8.21516 8.11176 8.1401 8.11859C6.51589 8.06399 5.22607 6.73323 5.22607 5.09537C5.22607 3.42339 6.57731 2.06534 8.25611 2.06534C9.92809 2.06534 11.2861 3.42339 11.2861 5.09537C11.2793 6.73323 9.98951 8.06399 8.3653 8.11859Z"
                        stroke="#71A600" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M4.95299 10.6368C3.30148 11.7424 3.30148 13.544 4.95299 14.6427C6.8297 15.8984 9.9075 15.8984 11.7842 14.6427C13.4357 13.5372 13.4357 11.7355 11.7842 10.6368C9.91433 9.38795 6.83652 9.38795 4.95299 10.6368Z"
                        stroke="#71A600" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    {{
                      selectedLanguage === 'en'
                        ? product.user_type
                        : product.user_type_tamil
                    }}
                  </small>
                </span>
                <span v-else class="not-mentioned">
                  <!-- Message to display when no data is available -->
                  <small class="text-uppercase text-nowrap prices">
                    <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17 18"
                    fill="none">
                    <path
                      d="M8.3653 8.11859C8.29706 8.11176 8.21516 8.11176 8.1401 8.11859C6.51589 8.06399 5.22607 6.73323 5.22607 5.09537C5.22607 3.42339 6.57731 2.06534 8.25611 2.06534C9.92809 2.06534 11.2861 3.42339 11.2861 5.09537C11.2793 6.73323 9.98951 8.06399 8.3653 8.11859Z"
                      stroke="#71A600" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M4.95299 10.6368C3.30148 11.7424 3.30148 13.544 4.95299 14.6427C6.8297 15.8984 9.9075 15.8984 11.7842 14.6427C13.4357 13.5372 13.4357 11.7355 11.7842 10.6368C9.91433 9.38795 6.83652 9.38795 4.95299 10.6368Z"
                      stroke="#71A600" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                    Not mentioned
                  </small>
                </span>
              </h2>
            </div>
            <div class="report mt-2">
              <button class="py-2 px-3 d-flex" @click="openForm">
                <i class="fa fa-flag pe-2 pt-1" aria-hidden="true"></i>
                  {{ translations.report }}          
      <i class="fa fa-angle-down ps-2 pt-1" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="desc pt-2 " >
            <h6>{{ product.descriptions }}</h6>
          </div>
          <div class="price my-1 d-flex my-3">
            <div v-if="product.price !== '0' && product.price !== '00' && product.price !== ''" class="d-flex">
              <h4 class>
                <span class="material-symbols-outlined">currency_rupee</span>
              </h4>
              <h4>{{ product.price }}/{{
                selectedLanguage === 'en'
                  ? product.quantity_type
                  : product.quantity_type_tamil
              }}</h4>
            </div>
            <div v-else>
              <p class="text-warning">{{translations.prnm}}</p>
            </div>
            <hr />
          </div>
          <div class="">
            <div class=" row m-0 p-0 ms-1">
              <!-- <div v-for="(item, index) in product.more" :key="index" :class="{ 'maxi': true, 'api-border': isFromApi }"
                class=" m-0 p-0 ms-1" >
                <div class="xfactor " >
                  <h6 class="" >{{ item.key }}</h6>
                  <p class="">{{product.more }}</p>

                </div>
              </div> -->
              <!-- <div v-html="product.more"></div> -->
            </div>
          </div>

          <div class="m-0 p-0 border-bottom mb-3">
            <div class="listdiv row text-break">
              <div class="listin ">
                <div class="d-flex">
                  <h5>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M8.20763 7.223C8.14654 7.21689 8.07323 7.21689 8.00603 7.223C6.55207 7.17412 5.39746 5.98286 5.39746 4.51668C5.39746 3.01996 6.60705 1.80426 8.10988 1.80426C9.6066 1.80426 10.8223 3.01996 10.8223 4.51668C10.8162 5.98286 9.66158 7.17412 8.20763 7.223Z"
                        stroke="#4E7300" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M5.15323 9.47726C3.67484 10.4669 3.67484 12.0797 5.15323 13.0633C6.83322 14.1873 9.58841 14.1873 11.2684 13.0633C12.7468 12.0736 12.7468 10.4608 11.2684 9.47726C9.59452 8.3593 6.83933 8.3593 5.15323 9.47726Z"
                        stroke="#4E7300" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </h5>
                  <h5 class="ps-1" style="font-family: {{ selectedLanguage === 'ta' ? 'TamilFont' : 'NormalFont' }}; font-size: 14px;  font-weight: 500;  ">{{ translations.cp }}</h5>
                </div>
                <h6 v-if="product.sellername">{{ product.sellername }}</h6>
                <h6 v-else>{{translations.nm}}</h6>
              </div>
              <div class="listin ">
                <div class="d-flex">
                  <h5 class="pe-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
                      <path
                        d="M9.84143 4.04383V9.78274C9.84143 12.0783 9.26754 12.6522 6.97198 12.6522H3.52863C1.23307 12.6522 0.65918 12.0783 0.65918 9.78274V4.04383C0.65918 1.74827 1.23307 1.17438 3.52863 1.17438H6.97198C9.26754 1.17438 9.84143 1.74827 9.84143 4.04383Z"
                        stroke="#4E7300" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6.3981 3.18298H4.10254" stroke="#4E7300" stroke-width="0.8" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M5.25037 10.9879C5.74164 10.9879 6.1399 10.5897 6.1399 10.0984C6.1399 9.60712 5.74164 9.20886 5.25037 9.20886C4.7591 9.20886 4.36084 9.60712 4.36084 10.0984C4.36084 10.5897 4.7591 10.9879 5.25037 10.9879Z"
                        stroke="#4E7300" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </h5>
                  <h5 style="font-family: {{ selectedLanguage === 'ta' ? 'TamilFont' : 'NormalFont' }}; font-size: 14px;  font-weight: 500;  " >{{ translations.contactno }}:</h5>
                </div>

                <h6>{{ product.phone_number }}</h6>
              </div>
              <div class="listin ">
                <div class="d-flex">
                  <h5 class="pe-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M7.96854 8.8144C9.05439 8.8144 9.93464 7.93415 9.93464 6.8483C9.93464 5.76245 9.05439 4.8822 7.96854 4.8822C6.88269 4.8822 6.00244 5.76245 6.00244 6.8483C6.00244 7.93415 6.88269 8.8144 7.96854 8.8144Z"
                        stroke="#4E7300" stroke-width="0.8" />
                      <path
                        d="M2.68813 5.70143C3.92955 0.244249 12.0145 0.25055 13.2496 5.70774C13.9743 8.90895 11.983 11.6186 10.2374 13.2949C8.97083 14.5174 6.96692 14.5174 5.69399 13.2949C3.95475 11.6186 1.96345 8.90265 2.68813 5.70143Z"
                        stroke="#4E7300" stroke-width="0.8" />
                    </svg>
                  </h5>
                  <h5 style="font-family: {{ selectedLanguage === 'ta' ? 'TamilFont' : 'NormalFont' }}; font-size: 14px;  font-weight: 500;  ">{{ translations.location }}:</h5>
                </div>
                <h6 style="font-family: {{ selectedLanguage === 'ta' ? 'TamilFont' : 'NormalFont' }}; font-size: 14px;  font-weight: 500;  ">
                  {{
                    selectedLanguage === 'en'
                      ? product.place
                      : product.tn_place
                  }},
                  {{
                    selectedLanguage === 'en'
                      ? product.market_name
                      : product.tn_name
                  }}
                </h6>
              </div>

              <div class="listin ">
                <div class="d-flex">
                  <h5 class="pe-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path d="M5.60791 1.30371V3.25923" stroke="#4E7300" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M10.823 1.30371V3.25923" stroke="#4E7300" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M4.95605 8.47461H10.1708" stroke="#4E7300" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M4.95605 11.082H8.21525" stroke="#4E7300" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M10.8225 2.28174C12.9932 2.39907 14.0817 3.22691 14.0817 6.29055V10.3189C14.0817 13.0045 13.4299 14.3473 10.1707 14.3473H6.25967C3.00047 14.3473 2.34863 13.0045 2.34863 10.3189V6.29055C2.34863 3.22691 3.4372 2.40559 5.60783 2.28174H10.8225Z"
                        stroke="#4E7300" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </h5>
                  <h5 style="font-family: {{ selectedLanguage === 'ta' ? 'TamilFont' : 'NormalFont' }}; font-size: 14px;  font-weight: 500;  ">{{ translations.posted }}:</h5>
                </div>
                <h6>{{ formatDate(product.created) }}</h6>
              </div>
            </div>
          </div>

          <div v-if="product.address && Object.keys(product.address).length > 0" class="address-details">
            <div class="d-flex add mt-0">
              <h4 class="pe-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M1.64646 2.85713C2.08659 0.953548 4.95297 0.953548 5.3876 2.85713C5.64618 3.97397 4.93646 4.92026 4.32028 5.50894C3.86914 5.93807 3.15942 5.93257 2.70828 5.50894C2.0976 4.92026 1.38788 3.97397 1.64646 2.85713Z"
                    stroke="#4E7300" stroke-width="0.8" />
                  <path
                    d="M8.79877 9.45918C9.2389 7.5556 12.1218 7.5556 12.5619 9.45918C12.8205 10.576 12.1108 11.5223 11.4891 12.111C11.038 12.5401 10.3227 12.5346 9.8716 12.111C9.24991 11.5223 8.54019 10.576 8.79877 9.45918Z"
                    stroke="#4E7300" stroke-width="0.8" />
                  <path
                    d="M7.11 3.07721H8.58445C9.60226 3.07721 10.0754 4.3371 9.31067 5.0083L4.91483 8.85398C4.15009 9.51968 4.62324 10.7796 5.63555 10.7796H7.11"
                    stroke="#4E7300" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3.52624 3.35229H3.5326" stroke="#4E7300" stroke-width="0.8" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M10.6786 9.95434H10.6849" stroke="#4E7300" stroke-width="0.8" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </h4>
              <h4 style="font-family: {{ selectedLanguage === 'ta' ? 'TamilFont' : 'NormalFont' }}; font-size: 14px;  font-weight: 500;  ">{{ translations.address }}</h4>
            </div>
            <div class="address ps-3 mt-0 pt-0 d-flex">
              <h6>
                {{ product.address.doorNo }} &nbsp;
                {{ product.address.areaStreet }} &nbsp;
                {{ product.address.townCity }} &nbsp;
                {{ product.address.pincode }} &nbsp;
                {{ product.address.landmark }} &nbsp;
              </h6>
            </div>
          </div>
          <div class="add d-flex mt-3">
            <h4 style="font-family: {{ selectedLanguage === 'ta' ? 'TamilFont' : 'NormalFont' }}; font-size: 14px;  font-weight: 500;  ">{{ translations.itemid }}:</h4>
            <h6 class="id ps-1">{{ product.uniqueId }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading" class="loading-spinner">
      <div class="row">
        <div class="col-sm-12 ">
          <p class="shimmer-line" style="height: 300px"></p>
          <div class="row d-flex">
            <div class="col my-2 mx-1">
              <p class="shimmer-line" style="height: 50px"></p>
            </div>
            <div class="col my-2 mx-1">
              <p class="shimmer-line" style="height: 50px"></p>
            </div>
            <div class="col my-2 mx-1">
              <p class="shimmer-line" style="height: 50px"></p>
            </div>
            <div class="col my-2 mx-1">
              <p class="shimmer-line" style="height: 50px"></p>
            </div>
            <div class="col my-2 mx-1">
              <p class="shimmer-line" style="height: 50px"></p>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <p class="shimmer-line my-2" style="height: 50px"></p>
          <p class="shimmer-line" style="height: 100px"></p>
          <p class="shimmer-line my-2" style="height: 20px"></p>
          <p class="shimmer-line" style="height: 40px"></p>
          <p class="shimmer-line my-2" style="height: 20px"></p>
          <p class="shimmer-line" style="height: 40px"></p>
        </div>
      </div>
    </div>
    <div v-else class="nodata">
      <!-- <p>No data available</p> -->
      <img src="../assets/img/drive/Empty Image.webp" loading="lazy" alt="empty" />
    </div>
    <div>
      <div v-if="showForm" class="modal">
        <div class="modal-content">
          <button class="close-button" @click="closeForm"><i class="fa-solid fa-x"></i></button>
          <h6>{{translations.reportitem}}</h6>
          <form @submit.prevent="submitForm">
            <div v-for="reportType in reportTypes" :key="reportType.id">
              <input type="radio" :id="'reportType' + reportType.id" :value="reportType.id" required="required" 

                v-model="selectedReportType" />
              <label :for="'reportType' + reportType.id" class="ms-2">
                {{
                  selectedLanguage === "en"
                  ? reportType.report
                  : reportType.report_tamil
                }}
              </label>
            </div>
            <div>
              <label for="additionalDetailsTextarea" class="ms-2">Additional Details:</label> <br>
              <textarea id="additionalDetailsTextarea" v-model="formData.additionalDetails" class="w-100" required style="min-height: 50px; max-height: 150px;"></textarea>
            </div>
            <button class="submit mt-3 mb-2" type="submit">{{ translations.submit }}</button>
          </form>
        </div>
      </div>
    </div>
    <div>
      <div v-if="showImage" class="modal">
        <div class="modal-content">
          <button class="close-button" @click="closeImage"><i class="fa-solid fa-x"></i></button>
          <div class="showimg">
            <img v-if="selectedImageIndex !== null" :src="product.images[selectedImageIndex]" loading="lazy"
            alt="Selected Product Image"  />
          </div>
        
        </div>
      </div>
    </div>
  </div>
  <!-- Image Popup -->
  <div >
    <div  class="row mx-md-4 mx-2" v-if="productList.length > 0">
      <div>
        <div class="exemple exemple-z-index-1 ">
          <span><svg xmlns="http://www.w3.org/2000/svg" width="49" height="54" viewBox="0 0 49 54"
              fill="none">
              <path
                d="M23.5621 19.565C23.5621 19.565 23.5852 19.5749 23.5976 19.576C23.6861 19.5765 23.9173 19.2526 24.1689 18.843C24.4473 18.4551 24.7565 17.9855 24.9444 17.671C25.3013 17.1643 25.4981 16.7237 25.5927 16.3159C25.7007 15.9189 25.7152 15.5662 25.6833 15.2159C25.654 14.8678 25.5794 14.5318 25.5382 14.1874C25.4971 13.843 25.4792 13.4863 25.593 13.1207C25.7778 12.5192 26.3439 11.5509 26.9478 10.871C27.2395 10.5161 27.5186 10.2043 27.7081 9.99772C27.8991 9.80571 27.9968 9.70703 27.9253 9.75138C27.0703 10.6813 26.1708 11.633 25.3979 12.6205C24.6246 13.6032 23.9708 14.6028 23.5311 15.5356C23.2938 15.9879 23.1406 16.4461 23.0222 16.8614C22.9092 17.2809 22.8229 17.651 22.8354 18.0034C22.8378 18.7031 23.0602 19.2298 23.5648 19.5672L23.5621 19.565Z"
                fill="#DEA401" />
              <path
                d="M29.7138 19.1414C29.4167 19.0253 29.1506 18.8765 28.8824 18.7082C28.6142 18.54 28.3422 18.3598 28.0474 18.2189C27.7505 18.0807 27.4204 17.9779 27.0432 17.9881C26.6752 18.0146 26.2493 18.1453 25.7464 18.4537C25.4701 18.6522 25.0528 18.981 24.7047 19.2902C24.3807 19.619 24.1297 19.918 24.1551 19.9964C24.1589 20.0083 24.1652 20.0223 24.1733 20.0288C24.6344 20.4199 25.1987 20.5079 25.8001 20.4348C26.0949 20.3891 26.4366 20.3458 26.7711 20.2566C27.1087 20.1744 27.4449 20.0555 27.8144 19.9724C28.5325 19.7716 29.3023 19.5949 30.0675 19.5389C30.8301 19.4808 31.6 19.5619 32.2907 19.8011C32.335 19.8014 32.2558 19.7729 32.0976 19.7158C31.9413 19.6782 31.7097 19.624 31.45 19.5605C30.8958 19.4051 30.2096 19.325 29.716 19.1387L29.7138 19.1414Z"
                fill="#DEA401" />
              <path
                d="M26.4648 16.6219C26.4648 16.6219 26.4836 16.6371 26.4916 16.6436C26.5686 16.6748 26.8867 16.4301 27.2484 16.1096C27.6277 15.8168 28.0775 15.4698 28.3562 15.2244C28.8569 14.8475 29.2026 14.4919 29.4437 14.1227C29.6939 13.7697 29.8528 13.4362 29.9677 13.0803C30.2037 12.3825 30.2755 11.6407 30.7819 11.0152C30.9922 10.7499 31.3134 10.4411 31.6863 10.1341C32.0598 9.83206 32.4772 9.57448 32.866 9.32482C33.6479 8.82014 34.292 8.54608 34.1337 8.58238C33.5997 8.85223 33.0323 9.20172 32.4814 9.4979C32.2032 9.65487 31.9273 9.78705 31.6528 9.95591C31.3805 10.1221 31.1087 10.2931 30.8386 10.4566C30.5711 10.6223 30.3075 10.7777 30.0529 10.9494C29.8021 11.133 29.5561 11.316 29.3118 11.4916C29.0777 11.6709 28.8458 11.8476 28.6182 12.0189C28.4029 12.2134 28.1995 12.4043 28.0031 12.592C27.2124 13.3161 26.6763 14.1265 26.3849 14.7883C26.1261 15.5032 26.1312 16.1118 26.4643 16.617L26.4648 16.6219Z"
                fill="#DEA401" />
              <path
                d="M32.7267 17.3368C32.1456 16.9951 31.7369 16.4954 31.1679 16.1058C30.8852 15.9169 30.5577 15.7451 30.1637 15.6883C29.7788 15.6478 29.306 15.6828 28.7405 15.8872C28.4241 16.031 27.9302 16.2622 27.528 16.501C27.1456 16.7647 26.8373 17.0084 26.853 17.0877C26.8567 17.0996 26.8604 17.1115 26.8684 17.118C27.2991 17.5467 27.8672 17.7178 28.4842 17.7462C29.1413 17.8294 29.8232 17.7282 30.606 17.7219C30.7917 17.7122 30.9843 17.6993 31.1797 17.6886C31.3792 17.6946 31.581 17.698 31.7854 17.7035C31.9823 17.7073 32.184 17.7107 32.3863 17.7189C32.5917 17.7341 32.804 17.7682 33.0105 17.7932C33.2126 17.8235 33.4147 17.8538 33.6217 17.8836C33.8217 17.9166 34.0376 17.9847 34.2374 18.0399C34.6488 18.1685 35.0204 18.2694 35.4087 18.4815C35.5084 18.4956 35.0672 18.2939 34.5052 18.0878C34.2103 17.969 33.882 17.8366 33.5715 17.7096C33.2647 17.5945 32.9617 17.4692 32.7262 17.3319L32.7267 17.3368Z"
                fill="#DEA401" />
              <path
                d="M30.4936 14.1821C30.5046 14.2398 30.6304 14.2217 30.8158 14.1629C30.9082 14.1311 31.0147 14.0929 31.1299 14.0439C31.2456 13.9998 31.3769 13.9639 31.5045 13.9161C32.0261 13.7384 32.5832 13.5005 32.7329 13.4528C33.3285 13.2551 33.7669 13.0101 34.1127 12.7256C34.4601 12.4558 34.7136 12.181 34.9317 11.8732C35.1504 11.5702 35.3303 11.2492 35.5387 10.9424C35.6426 10.7866 35.7519 10.6351 35.879 10.4891C36.0017 10.3485 36.1406 10.2209 36.3021 10.0983C36.5686 9.90082 36.9442 9.68939 37.3651 9.48793C37.5756 9.3872 37.7957 9.28545 38.0147 9.1961C38.2299 9.11698 38.4477 9.04003 38.6515 8.96947C39.4742 8.68891 40.1426 8.50562 39.9893 8.51929C37.7405 9.22741 35.2019 9.86532 33.3033 10.8215C32.8288 11.0728 32.3954 11.2952 32.0188 11.5681C31.6455 11.8481 31.3262 12.1273 31.0758 12.409C30.9504 12.5475 30.8422 12.6866 30.7518 12.8312C30.6646 12.9828 30.5969 13.1323 30.5464 13.2826C30.4454 13.583 30.4274 13.8796 30.491 14.1799L30.4936 14.1821Z"
                fill="#DEA401" />
              <path
                d="M32.9334 14.2865C32.7845 14.2948 32.2212 14.4032 31.7005 14.5194C31.5714 14.5526 31.4412 14.5761 31.3277 14.6175C31.2142 14.6589 31.113 14.7015 31.0265 14.7425C30.8582 14.8241 30.7602 14.8958 30.7901 14.9467C30.9455 15.2103 31.1493 15.4197 31.3878 15.5862C31.5071 15.6694 31.6351 15.7419 31.7696 15.8064C31.9099 15.88 32.0568 15.9456 32.2081 16.0058C32.5154 16.1258 32.8466 16.2162 33.1979 16.2873C33.5497 16.3632 33.9392 16.4696 34.3298 16.5415C35.9047 16.8744 37.7735 17.3163 39.3186 18.3228C39.4242 18.346 38.9848 18.1148 38.3934 17.7693C38.244 17.6794 38.087 17.5879 37.9295 17.4915C37.773 17.4048 37.616 17.3132 37.4595 17.2265C37.1504 17.0429 36.8527 16.8508 36.6342 16.665C36.5 16.5563 36.3783 16.4267 36.2668 16.3009C36.1558 16.1799 36.0514 16.0509 35.9513 15.9166C35.7441 15.651 35.5429 15.3726 35.3 15.1181C35.0576 14.8686 34.764 14.622 34.3867 14.4676C34.011 14.3279 33.5416 14.2545 32.9361 14.2887L32.9334 14.2865Z"
                fill="#DEA401" />
              <path
                d="M34.4875 13.1357C34.375 13.3734 36.4366 13.2204 36.7638 13.2719C38.0247 13.2351 38.8582 12.9411 39.6268 12.4967C40.0106 12.2697 40.369 12.0576 40.7424 11.8489C41.1164 11.645 41.5065 11.4542 41.9469 11.3219C42.3128 11.2048 42.7723 11.1368 43.2559 11.0884C43.7417 11.0373 44.2517 11.0057 44.7109 10.982C45.6238 10.9522 46.3065 10.9983 46.1675 10.9613C44.9457 10.9499 43.6774 10.963 42.4833 10.9118C41.2581 10.9375 40.0952 10.9027 39.0074 11.0441C37.9329 11.1253 36.9463 11.3887 36.1687 11.7013C35.3855 12.0539 34.8028 12.5376 34.4924 13.1351L34.4875 13.1357Z"
                fill="#DEA401" />
              <path
                d="M34.6057 13.9773C35.0568 14.507 35.6554 14.8272 36.3617 15.0746C37.0775 15.3652 37.8918 15.4957 38.8054 15.7287C39.7074 15.899 40.7012 16.1702 41.7036 16.4307C42.712 16.7716 43.7334 17.0964 44.6986 17.6334C44.8225 17.667 44.2706 17.3935 43.547 17.0521C43.186 16.8887 42.7782 16.7008 42.3937 16.5005C42.0064 16.2981 41.636 16.0915 41.3575 15.9194C41.0152 15.7 40.7173 15.4367 40.425 15.1555C40.1304 14.8771 39.8409 14.576 39.511 14.3356C38.8552 13.8446 38.0336 13.3883 36.7783 13.4786C36.4478 13.4422 34.4305 13.7821 34.6106 13.9768L34.6057 13.9773Z"
                fill="#DEA401" />
              <path
                d="M46.8196 14.613C46.0648 14.3486 45.4192 14.0481 44.8167 13.7603C44.2137 13.4677 43.6361 13.2534 43.0363 13.061C42.7347 12.9724 42.4309 12.8865 42.107 12.842C41.7814 12.805 41.4432 12.789 41.0838 12.8047C40.7245 12.8204 40.3455 12.8603 39.9416 12.9421C39.5326 13.0465 39.1029 13.1875 38.6487 13.3753C38.6487 13.3753 38.4583 13.3634 38.1002 13.3446C37.7426 13.3306 37.2197 13.3561 36.5524 13.3625C35.2287 13.433 33.2914 13.5533 31.114 14.3104C28.9502 15.0562 26.556 16.4599 24.6563 18.6417C23.7038 19.7194 22.819 20.9274 22.0809 22.2453C21.8789 22.5662 21.7054 22.9013 21.5324 23.2412C21.3594 23.5811 21.1757 23.9124 21.0081 24.2566C20.6933 24.9529 20.3505 25.6397 20.069 26.3496C18.8803 29.1738 17.9441 32.0867 17.036 34.8003C16.1327 37.5133 15.2528 40.0274 14.3472 42.112C13.4525 44.2053 12.515 45.8462 11.8143 46.9588C11.6293 47.2312 11.4655 47.4719 11.3234 47.6858C11.1787 47.8975 11.0439 48.0639 10.9385 48.2052C10.7275 48.4877 10.6155 48.637 10.6155 48.637L11.2067 49.0979C11.2067 49.0979 11.3204 48.9411 11.5352 48.6483C11.6423 48.4995 11.7783 48.3207 11.9246 48.1014C12.0684 47.88 12.2333 47.6269 12.4194 47.3421C13.1232 46.1874 14.0572 44.4904 14.9306 42.3576C15.8142 40.2287 16.6642 37.6858 17.5238 34.9553C18.3888 32.2291 19.2803 29.311 20.4129 26.4903C20.682 25.7792 21.0102 25.0939 21.3104 24.3992C21.4683 24.056 21.6445 23.7231 21.8126 23.3836C21.9786 23.0469 22.1472 22.7124 22.3395 22.3925C23.0485 21.0777 23.9041 19.8727 24.8296 18.7954C26.6706 16.6149 29.0115 15.1947 31.1474 14.4174C33.2969 13.6289 35.2263 13.4799 36.5479 13.39C37.2143 13.3739 37.741 13.3381 38.0959 13.3499C38.4534 13.3639 38.6433 13.3709 38.6433 13.3709C39.0848 13.5995 39.4868 13.7783 39.8625 13.918C40.2354 14.0777 40.5853 14.2055 40.9165 14.2959C41.2478 14.3863 41.5657 14.4437 41.8762 14.4773C42.1851 14.5185 42.4859 14.5532 42.789 14.5631C43.3914 14.593 44.003 14.5238 44.6542 14.5265C45.3069 14.5217 46.0125 14.5285 46.8147 14.6135L46.8196 14.613Z"
                fill="#DEA401" />
              <path
                d="M13.8176 33.506C13.2753 32.507 12.9739 29.992 12.9562 30.2443C13.3666 32.2076 13.3869 34.3618 13.5608 36.0848C13.7374 37.8099 14.0678 39.1529 15.2207 39.513C15.2309 39.5168 15.2455 39.5153 15.2574 39.5116C15.4346 39.4684 15.7182 38.0092 15.8123 37.3165C16.0992 34.9311 14.4691 34.7269 13.8176 33.506Z"
                fill="#DEA401" />
              <path
                d="M20.8968 35.8069C19.6449 36.42 18.2232 35.6289 17.0117 37.7236C16.6489 38.3364 15.9679 39.6606 16.0518 39.804C16.0603 39.8154 16.0662 39.8246 16.0813 39.8279C17.1962 40.3173 18.3374 39.4457 19.5493 38.1025C20.766 36.7588 22.0258 34.9833 23.5053 33.7323C23.6366 33.5097 21.9355 35.2924 20.8994 35.8091L20.8968 35.8069Z"
                fill="#DEA401" />
              <path
                d="M15.1312 29.5117C14.8775 28.9908 14.6935 28.0818 14.5727 27.3307C14.4682 26.5705 14.4247 25.971 14.4023 26.1084C14.6896 28.1853 14.6665 30.3932 14.8455 32.1647C15.025 33.9411 15.3991 35.2795 16.57 35.6009C16.5802 35.6047 16.5948 35.6032 16.6067 35.5995C16.7705 35.5454 17.0266 34.0817 17.1073 33.3782C17.3781 30.9798 15.7691 30.7659 15.1291 29.5144L15.1312 29.5117Z"
                fill="#DEA401" />
              <path
                d="M22.1761 31.9048C20.933 32.485 19.5017 31.6729 18.3102 33.7703C17.9619 34.3816 17.3046 35.7205 17.4079 35.8619C17.4165 35.8733 17.4245 35.8798 17.4374 35.8858C18.565 36.332 19.6713 35.4322 20.8527 34.1266C22.0364 32.8183 23.3011 31.1135 24.8001 30.025C24.866 29.9272 24.4697 30.2685 23.9228 30.6969C23.3851 31.1415 22.6974 31.678 22.1787 31.907L22.1761 31.9048Z"
                fill="#DEA401" />
              <path
                d="M16.673 25.3296C16.4507 24.7808 16.3214 23.8317 16.3007 23.0283C16.2611 22.2318 16.28 21.5938 16.2484 21.7371C16.3549 26.1343 15.5636 30.9157 17.8908 31.5599C17.9011 31.5637 17.9156 31.5622 17.9275 31.5585C18.0886 31.5023 18.362 30.0392 18.4649 29.3358C18.8209 26.9309 17.2176 26.655 16.6752 25.327L16.673 25.3296Z"
                fill="#DEA401" />
              <path
                d="M23.571 28.123C22.3059 28.5877 20.9132 27.7223 19.6541 29.7606C19.2897 30.3589 18.6212 31.6842 18.7271 31.8278C18.7357 31.8391 18.7438 31.8457 18.7567 31.8517C19.8842 32.2979 20.9855 31.4207 22.1822 30.1897C23.3848 28.9678 24.723 27.4247 26.2492 26.5715C26.3188 26.4856 25.9131 26.7616 25.3565 27.1198C24.7908 27.4618 24.0937 27.9329 23.5736 28.1252L23.571 28.123Z"
                fill="#DEA401" />
              <path
                d="M19.3584 27.557C19.3584 27.557 19.3832 27.5593 19.3951 27.5556C19.5518 27.5047 19.9152 26.0568 20.0601 25.3563C20.3282 24.169 20.0771 23.4635 19.7261 22.8815C19.3675 22.2979 18.8674 21.8397 18.6747 21.1281C18.5195 20.5403 18.5712 19.5377 18.6447 18.695C18.7655 17.8595 18.9183 17.1877 18.8527 17.3346C18.6595 18.4626 18.4536 19.6336 18.2575 20.7815C18.1853 21.3319 18.1152 21.8795 18.0457 22.41C17.978 22.9328 17.9642 23.4328 17.9426 23.9042C17.8906 25.7909 18.2018 27.185 19.3557 27.5548L19.3584 27.557Z"
                fill="#DEA401" />
              <path
                d="M27.8842 23.6244C27.959 23.5649 27.5486 23.726 26.962 23.94C26.4017 24.1929 25.6576 24.4972 25.132 24.6139C24.4989 24.7591 23.8504 24.594 23.1819 24.6127C22.5054 24.625 21.827 24.8535 21.1255 25.8188C20.7284 26.3861 19.9791 27.6684 20.0834 27.8195C20.092 27.8309 20.0979 27.8401 20.113 27.8434C21.2186 28.3386 22.3482 27.5198 23.613 26.4462C23.9366 26.1837 24.2464 25.8834 24.5911 25.6114C24.9331 25.3372 25.2821 25.0598 25.6321 24.7921C25.9961 24.5401 26.3702 24.3141 26.7383 24.101L27.017 23.9489L27.3063 23.8276C27.4993 23.7484 27.6912 23.6815 27.8837 23.6195L27.8842 23.6244Z"
                fill="#DEA401" />
              <path
                d="M21.105 23.5445C21.105 23.5445 21.1276 23.5495 21.1422 23.5479C21.2291 23.5339 21.3925 23.1729 21.564 22.725C21.736 22.2821 21.9547 21.7703 22.0684 21.4268C22.2569 20.8372 22.3664 20.3836 22.3547 19.9698C22.3512 19.5625 22.2427 19.208 22.128 18.8885C21.8836 18.2462 21.48 17.7017 21.4462 16.9365C21.4249 16.6171 21.4974 16.2091 21.6035 15.7706C21.7172 15.3338 21.8435 14.8539 22.0213 14.4422C22.3665 13.6151 22.708 12.9627 22.602 13.0991C22.3532 13.6754 22.0662 14.2386 21.8147 14.8127C21.5809 15.3923 21.3187 15.9578 21.1077 16.5203C20.9118 17.0861 20.7015 17.6313 20.5441 18.1661C20.3991 18.702 20.2634 19.2099 20.1619 19.6921C19.8182 21.6316 19.9921 23.0746 21.1033 23.552L21.105 23.5445Z"
                fill="#DEA401" />
              <path
                d="M27.131 21.5788C26.5042 21.5735 25.8995 21.2884 25.2399 21.181C24.9047 21.1229 24.5766 21.1328 24.2129 21.2472C23.8572 21.3682 23.4454 21.5859 23.0598 22.0292C22.8339 22.2863 22.4739 22.6925 22.2122 23.0762C21.9558 23.4642 21.7607 23.804 21.8007 23.8808C21.8071 23.8949 21.8152 23.9014 21.8232 23.9079C22.3485 24.2088 22.9161 24.1883 23.5086 23.9836C23.6556 23.9337 23.8059 23.8688 23.958 23.7963C24.1153 23.7282 24.2744 23.6525 24.4351 23.5693C24.7565 23.4028 25.0845 23.2062 25.4159 22.9945C25.7494 22.8023 26.1061 22.5978 26.4698 22.39C26.8297 22.1925 27.2099 22.0248 27.5799 21.8533C27.9563 21.6958 28.348 21.5907 28.7203 21.4877C29.1016 21.4009 29.4923 21.3795 29.8549 21.3708C29.9322 21.3357 29.5404 21.3695 28.9575 21.4087C28.6672 21.427 28.3545 21.4894 28.0312 21.5209C27.7105 21.5547 27.3835 21.5743 27.131 21.5788Z"
                fill="#DEA401" />
            </svg></span>
        </div>
        <div class="exemple exemple-z-index-2 ms-4 ps-1 pt-3">
 
          {{translations.sp}}
       
        </div>
      </div>
      <div  v-for="product in productList"
        :key="product.product_id || product.equip_product_id" class="product-detail col-lg-3 col-md-4 col-6  my-2 p-0 m-0 px-3 py-2 ">
        <div class="product-grid p-0 m-0" v-if="!loading">
          <div class="product-image">
            <a @click="this.$router.push(getProductRoute(product, this.$route.params))" class="image">
              <img class="pic-1 pt-2 px-2"
                :src="getProductImage(product)"
                 loading="lazy"
                :alt="`${product.category_name}-${product.product_id || product.equip_product_id}`"
                @error="handleImageError"
              />
            </a>
            
            <div class="del product-sale-labels" style="width:30px; height:30px;">
     
              <img class="delivery bg-success rounded-circle" @mouseover="showDeliveryMessage = true" @mouseleave="showDeliveryMessage = false" v-if="product.delivery" src="../assets/img/delivery2.png" loading="lazy" alt="delivery">
              <img v-else>
              <div v-if="showDeliveryMessage && product.delivery" class="delivery-above bg-success rounded text-light text-center text-nowrap m-0 px-2">
                {{ translations.delivery }}
                                </div>
            </div> 
            <span class="product-sale-label ">
                {{
                  selectedLanguage === 'en'
                    ? product.user_type
                    : product.user_type_tamil
                }}
         
            </span>       
              <div class="price my-1"  v-if="product.price !== '00' &&product.price !== '0' && product.price !== ''"  >
              <i class="fa fa-inr rupee ps-1" aria-hidden="true"></i>

              {{ product.price }}/{{
                selectedLanguage === 'en'
                  ? product.quantity_type
                  : product.quantity_type_tamil
              }}
            </div>
            <div v-else class="text-warning ">
              <!-- Not Mentioned -->
            </div>
            </div>
          <div class="product-content">
            <h3 class="title">
              <router-link to=""   :title="`${product.category_name}-${product.product_id || product.equip_product_id}`"  @click="this.$router.push(getProductRoute(product, this.$route.params))">
                {{ product.name || product.business_name }}
              </router-link>         
            </h3>
            <ul class="rating d-md-flex justify-content-between mt-1">
              <li class="cities-dis"  :class="{ 'tamil-fontx': selectedLanguage === 'ta', 'normal-fontx': selectedLanguage !== 'ta' }"> <span class="text-nowrap">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">

                  <defs>
                  </defs>
                  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                    <path d="M 45 1.802 c 16.583 0 30.075 13.491 30.075 30.075 c 0 7.102 -2.538 14.004 -7.145 19.434 L 45 78.317 L 22.07 51.311 c -4.608 -5.43 -7.145 -12.332 -7.145 -19.434 C 14.925 15.294 28.417 1.802 45 1.802 M 45 45.692 c 8.222 0 14.912 -6.689 14.912 -14.912 c 0 -8.222 -6.689 -14.912 -14.912 -14.912 S 30.088 22.557 30.088 30.78 C 30.088 39.002 36.778 45.692 45 45.692 M 45 0 C 27.395 0 13.123 14.272 13.123 31.877 c 0 7.86 2.858 15.043 7.573 20.6 L 45 81.101 l 24.304 -28.624 c 4.716 -5.558 7.573 -12.741 7.573 -20.6 C 76.877 14.272 62.605 0 45 0 L 45 0 z M 45 43.889 c -7.24 0 -13.11 -5.869 -13.11 -13.11 c 0 -7.24 5.869 -13.11 13.11 -13.11 s 13.11 5.869 13.11 13.11 C 58.11 38.02 52.24 43.889 45 43.889 L 45 43.889 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    <path d="M 58.814 71.531 l -1.575 1.575 c 9.772 1.532 15.583 4.707 15.583 7.094 c 0 3.261 -10.838 7.997 -27.822 7.997 S 17.178 83.461 17.178 80.2 c 0 -2.387 5.811 -5.562 15.583 -7.094 l -1.575 -1.575 c -9.401 1.643 -15.81 4.907 -15.81 8.669 c 0 5.412 13.263 9.8 29.625 9.8 c 16.361 0 29.625 -4.388 29.625 -9.8 C 74.625 76.439 68.215 73.174 58.814 71.531 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  </g>
                  </svg> {{
                    selectedLanguage === "en"
                    ? product.place
                    : product.tn_place
                  }},
              </span>    {{
selectedLanguage === "en"
? product.market_name
: product.tn_name
}}  </li>
<li>  {{
  new Date(product.created).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  })
}}</li>
            </ul>
          </div>
        </div>

        <!-- Shimmer effect while loading -->
        <div class="product-grid shimmer mt-3" v-else>
          <div class="product-image"></div>
          <div class="product-content">
            <div class="shimmer-line" style="height:150px"></div>
            <div class="shimmer-line"></div>
            <div class="shimmer-line"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading">
      <!-- Loading spinner or message -->
      <p>Loading...</p>
    </div>
    <div v-else>
      <!-- No data message -->
      <p>No data available</p>
    </div>
  </div>
</div>
  </div>
</template>

<script >
import axios from "axios";
import { en, ta } from "@/translations";
// import BottomAd from "@/components/homeaddon/BottomAd.vue";

import { getProductRoute } from "./product";

export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue
  components: {
// BottomAd,
},
  data() {
  
    return {
      productList: [],
      currentPage: 1,
      product: null,
      isLoading: true, // Set isLoading to true
      isImagePopupOpen: false,
      selectedImageIndex: 0, // Index of the clicked image
      showForm: false,
      showImage: false,
      showDeliveryMessage: false,
      formData: {
        name: "",
        selectedOption: null
      },
      reportTypes: [],
      selectedReportType: null
    };
  },

  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
  watch: {
    '$route.params.id': function (newId, oldId) {
      // This function will be called whenever the 'id' parameter in the URL changes
      console.log('ID changed. New ID:', newId, 'Old ID:', oldId);

      // You can perform actions or fetch data based on the new ID here
      this.fetchProductDetails(newId);
      this.productList = [];
      this.fetchData();
    }
  },
  mounted() {
    // Fetch data when the component is mounted
    // this.fetchData();
    this.fetchDatas();
  },
  methods: {
    getProductRoute : getProductRoute,
  navigateToAllList() {
    // pass category value after passing values
      // const location = this.$route.params.location;
      delete this.$route.params.id
      this.$router.push({
        name: 'AllList',
        params: {
          ...this.$route.params
        }
      });
    },
    getProductImage(product) {
    if (product.images && product.images.length > 0 && typeof product.images[0] === 'string' && product.images[0].trim() !== '') {
      return product.images[0];
    } else {
      return require('@/assets/img/drive/Empty Image.webp');
    }
  },
  
  handleImageError(event) {
    event.target.src = require('@/assets/img/drive/Empty Image.webp');
  },
    handleGalleryImageError(event) {
    event.target.src = require('@/assets/img/drive/Empty Image.webp');
  },
  handleThumbnailError(event) {
    event.target.src = require('@/assets/img/drive/Empty Image.webp');
  },
    openForm() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
    },
    openImage() {
      this.showImage = true;
    },
    closeImage() {
      this.showImage = false;
    },
    fetchDatas() {
      // Fetch data from the API endpoint
      fetch("https://vaiyal-app.herokuapp.com/reporttypes")
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            this.reportTypes = data.data;
          } else {
            console.error("Failed to fetch data:", data.msg);
          }
        })
        .catch(error => console.error("Error fetching data:", error));
    },
    submitForm() {
      const selectedReportType = this.selectedReportType || "0"; // Use "0" if no radio option is selected

// Extract relevant information from the product data
const productId = this.product.equip_product_id;
const categoryid = this.product.category_id;
const subcategoryid = this.product.sub_category_id || '0';
// Create the payload for the API request
const requestBody = {
  created: this.formatDate(new Date()), // Assuming you want the current date
  type: "equipments",
  id: productId,
  categoryid: categoryid,
  subcategoryid: subcategoryid,
  userid: "0",
  report_type_id: selectedReportType,
  others: this.formData.additionalDetails,
};
        // console.log(requestBody);
        // Include the additional details if provided
        if (this.formData.name) {
          requestBody.additionalDetails = this.formData.name;
        }

        // Send a POST request to the API endpoint
        axios
          .post("https://vaiyal-app.herokuapp.com/createreports", requestBody)
          .then(response => {
            console.log("Report submitted successfully:", response.data);
            // Display a success message
            this.showSuccessMessage();
            // Close the form after successful submission
            this.showForm = false;
            // Reset the form data
            this.formData = {};
                  // Reset the form data
      this.selectedReportType = null; // Reset the selected radio option
      this.formData.additionalDetails = ""; // Reset the textarea
      // If you have other form fields, reset them here
          })
          .catch(error => {
            console.error("Error submitting report:", error);
          });
     
    },
    async fetchData() {
      try {
        const product = this.$route.params.product?.replace(/-/g, ' ');

        const response = await axios.post(
          `https://vaiyal-app.herokuapp.com/get/productlist?page=${this.currentPage}&term=${product}`
          // ` https://vaiyal-app.herokuapp.com/get/productlist?page=${this.currentPage}&term=${product}`

        );
        let i = 1
          response.data.data.map(item => { 
            if (item?.equip_product_id != this.product?.equip_product_id) {
              this.productList.push(item)
              console.log("equiplist:",i++, this.productList);
            } else {
              console.log("skipping:",item);
            }
          });
          console.log("equip:",this.product);
          console.log("equiplist:",this.productList);

        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    },
    showSuccessMessage() {
      // You can customize the success message display according to your UI/UX
      alert("Report submitted successfully!");
      // Alternatively, you can set a property to control the display in your template
      // this.successMessage = "Report submitted successfully!";
    },
    truncatetitle(name) {
      if (name.length > 22) {
        return name.substring(0, 18) + "...";
      }
      return name;
    },
    goBack() {
      this.$router.go(-1);
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    formatDate(date) {
      const dateObject = new Date(date);
      const day = String(dateObject.getDate()).padStart(2, "0");
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const year = String(dateObject.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    },
async fetchProductDetails(productId) {
      try {
        // const response = await axios.get(`https://vaiyal-app.herokuapp.com/product/${productId}`);
        const response = await axios.get(`https://vaiyal-app.herokuapp.com/equipproduct/${productId}`);
        this.product = response.data.data;
        this.subCategoryName = this.product?.sub_category_name; // Extract sub-category name

        console.log("Product response:", this.product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
      this.isLoading = false;
    },
   
  },
  created() {
    this.fetchData();
    const productId = this.$route.params.id;
    this.fetchProductDetails(productId);
  }
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap");

.product-details {
  text-transform: capitalize;
  overflow: hidden;
  background: white;
}

.pagelink {
  background-image: url("../assets/img/drive/Detail\ Banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.pagelink h1 {
  color: #238601;
  font-family: "Mukta";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pagelink h6 {
  color: #238601;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.pagelink h6 a {
  text-decoration: none;
  color: #238601;
}

.pagelink h6 a:hover {
  text-decoration: none;
  color: #134801;
}

.containers.detail {
  margin-top: 10px;
}

.detailgrid {
  margin: 20px 0;
}

.imageleft {
  position: relative;
}

.image-row {
  margin-bottom: 10px;
}

.image-row img {
  width: 80px;
  height: 80px;
  cursor: pointer;
  margin-right: 10px;
  object-fit: cover;
  padding: 5px;
  background: #eaeaea;
  border-radius: 10px;
}

.titlehead {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.titlehead h2 {
  margin: 0;
  color: #232323;
  font-family: "Mukta";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.price {
  border-bottom: 1px solid #d6d6d6;
}

.price h4 {
  color: #4e7300;
  font-family: "Mukta";
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
}

.desc h6 {
  margin: 0;
  color: #9c9c9c;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 121%;
  height: 100%;
}

.listin h5 {
  color: #4e7300;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 121%;
}

.listin {
  margin: 10px 0px;
}

.listdiv {
  margin-top: 20px;
  width: 100%;
}

.add h4 {
  color: #4e7300;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 121%;
}

.address {
  color: #252525;
  font-family: "Mukta";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 121%;
}

.id {
  color: #9c9c9c;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 121%;
}

.listin h6 {
  padding-left: 17px;
  color: #252525;
  font-family: "Mukta";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 121%;
}

.loading-spinner {
  text-align: center;
  margin-top: 20px;
}

.nodata {
  text-align: center;
  margin-top: 20px;
}

.mainimage {
  border-radius: 10px;
  background: #eaeaea;
  width: 100%;
  height: 400px;
}

.mainimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.shimmer {
  animation: shimmer 1s infinite linear;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
}

.shimmer-line {
  height: 20px;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

.maxi,
.listin {
  width: 25%;
}

.maxi h6 {
  color: #797979;
  font-family: "Mukta";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.maxi p {
  color: #252525;
  font-family: "Mukta";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.normal-fontx {
  font-size: 14px;
}

.tamil-fontx {
  font-size: 10px;
}

@media screen and (min-width: 1800px) {

  .mainimage {
    width: 100%;
    height: 500px;
  }
}

@media screen and (min-device-width: 1600px) and (max-device-width: 1800px) {
}

@media screen and (min-device-width: 1400px) and (max-device-width: 1600px) {
}

@media screen and (min-device-width: 900px) and (max-device-width: 1150px) {

  .maxi,
  .listin {
    width: 33.33%;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 900px) {

  .maxi,
  .listin {
    width: 33.33%;
  }
}

@media screen and (max-width: 768px) {
  .imageleft {
    margin-bottom: 0px;
  }

  .maxi,
  .listin {
    width: 50%;
  }

  .mainimage img {
    width: 94%;
  }

  .normal-fontx {
    font-size: 12px;
  }

  .tamil-fontx {
    font-size: 10px;
  }

}

@media only screen and (max-device-width: 480px) {

  .maxi,
  .listin {
    width: 50%;
  }

  .listin h6 {
    padding-left: 10px;
    font-size: 16px;
  }

  .mainimage img {
    width: 94%;
  }

  .listin h5 {
    font-size: 14px;
  }

  .normal-fontx {
    font-size: 12px;
  }

  .tamil-fontx {
    font-size: 10px;
  }

  .pagelink {
  background-image: url("../assets/img/drive/Detail-Banner-mbl.png");
 
  background-size: cover;
  position: relative;
}
}

@media only screen and (max-device-width: 380px) {

  .maxi,
  .listin {
    width: 100%;
  }

  .listin h6 {
    padding-left: 10px;
    font-size: 16px;
  }

  .listin h5 {
    font-size: 14px;
  }

  .mainimage img {
    width: 94%;
  }
  .modal-content {
    max-width: 400px;
    height: auto !important;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f4f4f4;
    position: relative;
  }
  .showimg{
    height:300px !important;
    width: auto;
  }
  .showimg img {
    height: 100%;
    width: 100%;
    object-fit: contain
  }
}

.prices {
  background: #f5fbe9;
  font-size: 14px;
  padding: 4px 6px 4px 15px;
  border-radius: 1px 3px 3px 1px;
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 20% 100%, 0 50%);
  color: #71a600;
  font-family: "Mukta", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  margin-top: 7px;
  margin-left: 5px;
  box-shadow: 1px 1px 1px 1px #71a600;
}

.report button {
  border: none;
  background: #feb1b1;
  font-size: 14px;
  border-radius: 10px;
}

.fa-flag,
.fa-angle-down {
  color: #b80000;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.modal-content {
  max-width: 400px;
  height: auto;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  position: relative;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
  color: #151414;
  background: transparent;
  border: none;
  border-radius: 50%;
}

.close-button:hover {
  background-color: #4c5800;
  transition: background-color 0.3s;
  color: #c2c2c2;
}

.close-button:not(:hover) {
  transition: background-color 0.3s;
}

.cus-type textarea {
  border-radius: 6px;
  border: 1px solid #71a600;
}

.cus-type textarea:focus {
  outline: none;
  border-radius: 6px;
  border: 1px solid #71a600;
}

label {
  font-size: 16px;
}

.open-form {
  cursor: pointer;
  padding: 5px;
  background-color: #feb1b1;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
}

.modal-content .submit {
  border: none;
  background: #71a600;
  color: white;
  font-family: "Mukta";
  font-size: 14px;
  border-radius: 10px;
  padding: 5px 15px;
}
.showimg{
  height:500px;
  width: auto;
}
.showimg img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.exemple {
  font-family: "Satisfy";
  font-size: 20px;
  font-weight: 600;
  color: #71a600;
}

.exemple-z-index-1 {
  position: absolute;
}

.loco {
  overflow: hidden;
}

.delivery {
  width: 60px;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}

.delivery-above {
  position: absolute;
  z-index: 9999999;
  right: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  font-size: 14px;
  font-family: "mukta";
  border-radius: 8px solid green;
}

.product-sale-labels {
  color: #5a5a5a;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 3px;
  position: absolute;
  right: 0px;
  top: 8px;
}

.del .delivery {
  width: 30px;
  height: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}

.del .delivery-above {
  position: absolute;
  z-index: 99999;
  right: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  font-size: 12px;
  font-family: "mukta";
  border-radius: 8px solid green;
}
textarea{
  bottom: none;
}

.cities-dis{
  white-space: nowrap; 
  width:200px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}


.product-grid .rating {
  padding: 0;
  margin: 0;
  list-style: none;
}

.product-grid .rating li {
  color: #1F5F5B;
  font-size: 12px;
  display: inline-block;
}

.product-grid .rating li.far {
  color: #dbdbdb;
}
@media (min-width: 1800px) {
  .full{
    margin: 0 10%;
  }
  }
</style>
<style src="../assets/img/alllist.css"></style>